import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ITherapist } from "../common/interfaces";
import { SchoolContext } from "../context/SchoolContext";
import { TherapistContext } from "../context/TherapistContext";
import SortableTable from "./Display/Table/SortableTable";
import { OverrideScript, OverrideVerse } from "../common/Script";
import {
  AssignmentTurnedInOutlinedIcon,
  DeleteIcon,
  EditIcon,
  WarningRoundedIcon,
} from "../icons";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import {
  Button,
  FormControlLabel,
  Paper,
  PaperProps,
  Switch,
  Typography,
} from "@material-ui/core";
import { SingleWorkItemEditor } from "./Input/SingleWorkItemEditor";
import { RangeDatePicker } from "./RangeDatePicker/RangeDatePicker";
import ConfirmationModal from "./ConfirmationModal";
import { useApi } from "../api/useApi";
import Toaster, { ToastType } from "../common/Toaster";
import { createLogger } from "./Logging/Logging";
import { BoldNumberDisplay } from "./Display/BoldNumberDisplay";
import { DateTime } from "luxon";
import IconText from "./Display/IconText";
import HeaderWithRightSection from "./Display/HeaderWithRightSection";
import SectionHeaderText from "./Display/SectionHeaderText";
import Row from "./Display/Row";
import Column from "./Display/Column";
import ShowInvoicedSwitch from "./Input/ShowInvoicedToggle";
import WorkItemForm from "./Input/WorkItemForm";
import useModal from "../hooks/useModal";
import { ServiceRecord } from "../api/schemas/service-records/schema";
import { useDateRanges } from "../hooks/useDateRanges";

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableRow: {
    "&$hover:hover": {
      backgroundColor: "blue",
    },
  },
  leftColumn: {
    flex: "30%",
  },
  rightColumn: {
    flex: "70%",
  },
  right: {
    marginLeft: "auto",
  },
  inline: {
    display: "flex",
  },
  densePaddingSwitch: {
    margin: "5px",
  },
});

interface WorkItemTableProps {
  rows: ServiceRecord[];
  areRowsLoading: boolean;
  columnsToHide?: string[];
  start: DateTime;
  end: DateTime;
  showInvoicedWorkItems: boolean;
  tableContainerProps?: CSSProperties;
  therapist?: ITherapist;
  listStudents?: boolean;
  showNewButton?: boolean;
  showTotals?: boolean;
  altToolbar?: JSX.Element;
  altFooter?: JSX.Element;
  defaultDensePadding?: boolean;
  paperProps?: PaperProps;
  paginationProps?: {
    rowsPerPageOptions?: number[];
    defaultRowsPerPage?: number;
  };
  setStartDate: (start: DateTime) => void;
  setEndDate: (end: DateTime) => void;
  setShowInvoicedWorkItems: (show: boolean) => void;
  onDelete?: (item: ServiceRecord) => void;
  invalidateServiceRecord: () => void;
}

const logger = createLogger("WorkItemTable");

export default function WorkItemTable(props: WorkItemTableProps): JSX.Element {
  const {
    rows,
    areRowsLoading,
    columnsToHide,
    therapist,
    showNewButton,
    listStudents,
    invalidateServiceRecord,
  } = props;
  const { modal: WorkItemFormModal, toggle } = useModal();
  const { schools } = React.useContext(SchoolContext);
  const { therapists } = React.useContext(TherapistContext);
  const api = useApi();

  const { dateRanges } = useDateRanges();
  const [showEditWorkItem, setShowEditWorkItem] =
    React.useState<boolean>(false);

  const [editingWorkItem, setEditingWorkItem] = React.useState<
    ServiceRecord | undefined
  >(undefined);
  const [workItemToDelete, setWorkItemToDelete] = React.useState<
    ServiceRecord | undefined
  >(undefined);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState<boolean>(false);
  const [dense, setDense] = React.useState<boolean>(
    props.defaultDensePadding ?? false
  );
  const onStartWorkItemEdit = React.useCallback((item: ServiceRecord) => {
    setEditingWorkItem(item);
    setShowEditWorkItem(true);
  }, []);

  const onStopWorkItemEdit = React.useCallback(() => {
    setEditingWorkItem(undefined);
    setShowEditWorkItem(false);
  }, [setEditingWorkItem]);

  const onStartWorkItemDelete = React.useCallback((item: ServiceRecord) => {
    setWorkItemToDelete(item);
    setShowDeleteConfirmation(true);
  }, []);

  const deleteWorkItem = React.useCallback(() => {
    if (!workItemToDelete) return;
    setShowDeleteConfirmation(false);
    api.serviceRecord
      .delete({ id: workItemToDelete._id })
      .then(() => {
        Toaster("Work item deleted", ToastType.good);
      })
      .catch((error) => {
        logger.error(error);
        Toaster(error.message, ToastType.error);
      })
      .finally(() => invalidateServiceRecord());
  }, [api.serviceRecord, invalidateServiceRecord, workItemToDelete]);

  const styles = useStyles();

  const script: OverrideScript<ServiceRecord> = React.useMemo(
    () => [
      {
        id: "school",
        text: "School",
        getField: (member: ServiceRecord) => member.schoolName,
      },
      {
        id: "dateWorked",
        text: "Date Worked",
        getField: (member: ServiceRecord) =>
          DateTime.fromISO(member.dateWorked).toFormat("LLL dd, yyyy"),
      },
      {
        id: "dateLogged",
        text: "Date Logged",
        getField: (member: ServiceRecord) =>
          DateTime.fromISO(member.dateLogged).toFormat("LLL dd, yyyy"),
      },
      {
        id: "service",
        text: "Service",
        getField: (member: ServiceRecord) => member.className,
      },
      {
        id: "workType",
        text: "Work Type",
        getField: (member: ServiceRecord) => member.workTypeName,
      },
      {
        id: "units",
        text: "Units",
        getField: (member: ServiceRecord) => member.units,
      },
      {
        id: "students",
        text: "Students",
        getField: (member: ServiceRecord) =>
          listStudents
            ? member.students.map((std) => std.name).join(", ")
            : member.students.length,
      },
      {
        id: "therapist",
        text: "Therapist",
        getField: (member: ServiceRecord) =>
          therapists.find((therp) => therp.userId === member.therapistUserId)
            ?.name ?? "Unknown",
      },
      {
        id: "invoiceId",
        text: "Invoice ID",
        getField: (member: ServiceRecord) => member.invoiceId,
        comparator: (a: ServiceRecord, b: ServiceRecord) => {
          if (a.invoiceId === b.invoiceId) return 0;

          if (a.invoiceId === null) return -1;
          if (b.invoiceId === null) return 1;

          return a > b ? 1 : -1;
        },
      },
      {
        isAction: true,
        id: "action",
        text: "Action",
        align: "right",
        action: [onStartWorkItemEdit, onStartWorkItemDelete],
        actionIcon: [
          <EditIcon key={"edit"} fontSize="small" />,
          <DeleteIcon key={"delete"} fontSize="small" />,
        ],
        setDisabled: (member: ServiceRecord) =>
          !(!member.invoiceId || member.invoiceId === ""),
      },
    ],
    [listStudents, onStartWorkItemDelete, onStartWorkItemEdit, therapists]
  );

  const filteredScript = React.useMemo(
    () =>
      script.filter(
        (verse: OverrideVerse<ServiceRecord>) =>
          !columnsToHide?.includes(verse.id)
      ),
    [script, columnsToHide]
  );

  const tableToolbar = React.useMemo(() => {
    if (props.altToolbar) return props.altToolbar;
    const totalUnitHours =
      rows.map((r) => r.units).reduce((a, u) => a + u, 0) / 4;
    return (
      <HeaderWithRightSection
        style={{ margin: "2px" }}
        leftSection={
          <Row>
            <SectionHeaderText variant="h6">Work Items</SectionHeaderText>
            <BoldNumberDisplay
              label={"Units"}
              num={totalUnitHours}
              unit={"Hours"}
              variant={"h4"}
              compact
            />
          </Row>
        }
        rightSection={
          <Row gap="m">
            <RangeDatePicker
              start={props.start}
              end={props.end}
              predefinedOptions={Object.values(dateRanges)}
              setStart={props.setStartDate}
              setEnd={props.setEndDate}
            />
            <ShowInvoicedSwitch
              toggle={() =>
                props.setShowInvoicedWorkItems(!props.showInvoicedWorkItems)
              }
              showInvoiced={props.showInvoicedWorkItems}
            />
          </Row>
        }
      />
    );
  }, [dateRanges, props, rows]);

  return (
    <Paper {...props.paperProps}>
      <SortableTable
        label={"Work Items"}
        script={filteredScript}
        rows={rows}
        selectionFilters={[]}
        tableContainerProps={props.tableContainerProps}
        noContentText={`No records found between ${props.start.toFormat(
          "LLL dd, y"
        )} and ${props.end.toFormat("LLL dd, y")}`}
        altToolbar={tableToolbar}
        defaultSortKey="dateWorked"
        defaultSortOrder="desc"
        densePadding={dense}
        altFooter={
          <HeaderWithRightSection
            leftSection={
              <FormControlLabel
                className={styles.densePaddingSwitch}
                control={
                  <Switch
                    checked={dense}
                    color="primary"
                    onChange={() => setDense(!dense)}
                  />
                }
                label="Dense padding"
              />
            }
            rightSection={
              showNewButton && (
                <Column center>
                  <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    aria-label="add-new-work-time"
                    onClick={toggle}
                  >
                    <IconText
                      variant="subtitle2"
                      icon={<AssignmentTurnedInOutlinedIcon />}
                      text={"New Service Record"}
                    />
                  </Button>
                </Column>
              )
            }
          />
        }
        loading={areRowsLoading}
        rowsPerPageOptions={props.paginationProps?.rowsPerPageOptions}
        defaultRowsPerPage={props.paginationProps?.defaultRowsPerPage}
      />

      <SingleWorkItemEditor
        open={showEditWorkItem}
        editingWorkItem={editingWorkItem}
        schools={schools}
        setOpen={onStopWorkItemEdit}
        invalidateServiceRecordQuery={invalidateServiceRecord}
      />
      {therapist && (
        <WorkItemFormModal
          onClose={() => {
            invalidateServiceRecord();
            return true;
          }}
        >
          <WorkItemForm therapist={therapist} setOpen={toggle} />
        </WorkItemFormModal>
      )}
      <ConfirmationModal
        message={
          <div style={{ display: "flex", alignItems: "center" }}>
            <WarningRoundedIcon
              fontSize="large"
              style={{ marginRight: "10px" }}
            />
            <Typography align="center">
              This operation is <b>irreversible!</b> Are you sure you want to
              delete this work item?
            </Typography>
          </div>
        }
        confirmText={"No, don't delete"}
        cancelText={"Yes, delete item"}
        onConfirm={() => {
          setWorkItemToDelete(undefined);
          setShowDeleteConfirmation(false);
        }}
        onCancel={() => {
          invalidateServiceRecord();
          deleteWorkItem();
        }}
        open={showDeleteConfirmation}
        setOpen={(show: boolean) => setShowDeleteConfirmation(show)}
      />
    </Paper>
  );
}
