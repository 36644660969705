import { Paper } from "@material-ui/core";
import React from "react";
import { TypeUnitPairSelector } from "./TypeUnitPairSelector";
import { DeleteIcon } from "../../icons";
import { IPartialWorkItem, IWorkType } from "../../common/interfaces";
import { SimpleActionTable } from "../Display/Table/SimpleActionTable";
import { OverrideScript } from "../../common/Script";
import { ServicesContext } from "../../context/ServicesContext";
import { isObjectEmpty } from "../../common/Utils";

export interface IWorkItemSelectorProps {
  workTypes: { [uuid: string]: IWorkType };
  additionalItems: IPartialWorkItem[];
  classId: string;
  addAdditionalItem: (item: IPartialWorkItem) => void;
  removeAdditionalItem: (id: number) => void;
  hideBorder?: boolean;
}

/** NOTE: This is actually a work *type* selector */
export function WorkItemSelector(props: IWorkItemSelectorProps): JSX.Element {
  const {
    workTypes,
    additionalItems,
    addAdditionalItem,
    removeAdditionalItem,
    classId: classId,
    hideBorder,
  } = props;

  const { classes } = React.useContext(ServicesContext);

  const script: OverrideScript<IPartialWorkItem> = [
    {
      id: "name",
      text: "Work Type",
      getField: (item: IPartialWorkItem) => {
        if (isObjectEmpty(classes)) return "";
        return classes[item.classId].workTypes[item.workTypeId].name;
      },
    },
    {
      id: "units",
      text: "Units",
      align: "right",
      getField: (item: IPartialWorkItem) => item.unit,
    },
    {
      isAction: true,
      id: "remove",
      text: "Remove Item",
      actionIcon: <DeleteIcon />,
      align: "right",
      action: (item: IPartialWorkItem) => removeAdditionalItem(item.id),
    },
  ];

  return (
    <Paper
      elevation={hideBorder ? 0 : 3}
      style={{
        border: hideBorder ? "1px solid" : undefined,
        borderColor: hideBorder ? "rgb(204, 204, 204)" : undefined,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SimpleActionTable
        population={additionalItems}
        title={"Work Types"}
        makeDisplay={(item: IPartialWorkItem) =>
          isObjectEmpty(classes)
            ? ""
            : classes[item.classId].workTypes[item.workTypeId].name
        }
        overrideScript={script}
      />
      <TypeUnitPairSelector
        workTypes={workTypes}
        classId={classId}
        addAdditionalItem={addAdditionalItem}
        removeAdditionalItem={removeAdditionalItem}
        additionalItems={additionalItems}
      />
    </Paper>
  );
}
