import {
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  TextField,
  Box,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import {
  IClassification,
  IGrade,
  IWorkType,
  ModalProps,
} from "../common/interfaces";
import clsx from "clsx";
import React, { ChangeEvent } from "react";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "./ConfirmationModal";
import { NewWorkTypeInput } from "./Input/NewWorkTypeInput";
import { NewGradeInput } from "./Input/NewGradeInput";
import { ConfirmDenyButtonAction } from "./Input/ConfirmDenyButtonAction";
import Toaster, { ToastType } from "../common/Toaster";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContentArea: {
      maxHeight: "95%",
      overflowY: "auto",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    inlineContainer: {
      display: "inline",
    },
    centeringContainer: {
      display: "block",
      margin: "auto",
    },
    defaultMessage: {
      fontSize: 18,
      textAlign: "center",
    },
    button: {
      margin: "10px",
    },
    table: {
      padding: "10px",
    },
    label: {
      textAlign: "center",
      fontSize: "12",
      width: "100%",
      margin: "auto",
    },
    error: {
      border: "1px red solid",
    },
    scrollable: {
      overflow: "auto",
    },
    input: {
      width: "100%",
      padding: "10px",
      minWidth: "10%",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },
    slimNumberInput: {
      width: "25%",
      margin: "5px",
    },
    rightPad: {
      paddingRight: "5px",
    },
    tableCellHack: {
      paddingLeft: "294%",
      paddingRight: "24%",
    },
    padTop: {
      paddingTop: "5px",
    },
    fixedMaxHieght: {
      maxHeight: "200px",
    },
    controllerContainer: {
      display: "flex",
      flexDirection: "row",
      paddingTop: "10px",
    },
    nameTextField: {
      margin: "10px 0 10px 0",
    },
    gradeTable: {
      marginBottom: "10px",
    },
  })
);

export interface AddGrade {
  kind: "AddGrade";
  grade: IGrade;
  backendId?: string;
}

export interface EditGrade {
  kind: "EditGrade";
  grade: IGrade;
  backendId: string;
}

export interface RemoveGrade {
  kind: "RemoveGrade";
}

export interface AddWorkType {
  kind: "AddWorkType";
  workType: IWorkType;
}

export interface UpdateWorkType {
  kind: "UpdateWorkType";
  workType: IWorkType;
}

export interface RemoveWorkType {
  kind: "RemoveWorkType";
}

export interface UpdateClassName {
  kind: "UpdateClassName";
  name: string;
}

export type EditEvent =
  | AddGrade
  | EditGrade
  | RemoveGrade
  | AddWorkType
  | RemoveWorkType
  | UpdateWorkType
  | UpdateClassName;

interface IClassificationEditModalProps extends ModalProps {
  uuid?: string;
  classification: IClassification | undefined;
  handleExit: () => void;
  handleSave: (
    newClass: IClassification,
    editEvents: Map<string, EditEvent>
  ) => Promise<void>;
  safeName: (name: string) => boolean;
}

export default function ClassificationEditModal(
  props: IClassificationEditModalProps
): JSX.Element {
  const { open, setOpen, classification, handleExit, handleSave } = props;
  const classes = useStyles();
  const [cancelConfirmOpen, setCancelConfirmOpen] =
    React.useState<boolean>(false);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  // This map holds all local edits made to settings objects. The key uniquely identifies both
  // the object itself and the edit event. Objects that have been created locally are given a
  // locally-generated id that will no longer apply after the object has been pushed to the
  // backend.
  const editEvents = React.useMemo(() => new Map<string, EditEvent>(), []);

  const creatingNew = classification === undefined;
  const header = creatingNew
    ? "Create New Related Service"
    : `Edit ${classification?.name}`;

  const makeNewClass = () => {
    return {
      name: "New Related Service",
      grades: {},
      workTypes: {},
      special: false,
    } as IClassification;
  };

  const [editingClass, setEditingClass] =
    React.useState<IClassification>(makeNewClass());

  React.useEffect(() => {
    if (classification) setEditingClass(classification);
  }, [classification, open]);

  const handleAddGrade = React.useCallback(
    (grade: IGrade) => {
      if (grade.name === editingClass.name) {
        Toaster(
          "Grades cannot have the same name as their parent service!" +
            ` ${grade.name} is the name of the service.`,
          ToastType.error
        );
        return;
      }
      setIsEditing(true);

      const eventId = uuidv4();
      editEvents.set(eventId, { kind: "AddGrade", grade });

      setEditingClass((prev) => ({
        ...prev,
        grades: { ...prev.grades, [eventId]: grade },
      }));
    },
    [editingClass.name, editEvents]
  );

  const handleEditGrade = React.useCallback(
    (id: string, grade: IGrade) => {
      if (grade.name === editingClass.name) {
        Toaster(
          "Grades cannot have the same name as their parent service!" +
            ` ${grade.name} is the name of the service.`,
          ToastType.error
        );
        return;
      }
      setIsEditing(true);

      editEvents.set(id, { kind: "EditGrade", grade, backendId: id });

      setEditingClass((prev) => ({
        ...prev,
        grades: { ...prev.grades, [id]: grade },
      }));
    },
    [editingClass.name, editEvents]
  );

  const handleRemoveGrade = React.useCallback(
    (id: string) => {
      setIsEditing(true);

      editEvents.set(id, { kind: "RemoveGrade" });

      setEditingClass((prev) => {
        const next = structuredClone(prev);
        delete next.grades[id];
        return next;
      });
    },
    [editEvents]
  );

  const handleAddWorkType = React.useCallback(
    (workType: IWorkType) => {
      setIsEditing(true);

      const eventId = uuidv4();
      editEvents.set(eventId, { kind: "AddWorkType", workType });

      setEditingClass((prev) => ({
        ...prev,
        workTypes: { ...prev.workTypes, [eventId]: workType },
      }));
    },
    [editEvents]
  );

  const handleUpdateWorkType = React.useCallback(
    (id: string, updates: IWorkType) => {
      setIsEditing(true);

      const existingEvent = editEvents.get(id);

      if (
        existingEvent === undefined ||
        existingEvent.kind === "UpdateWorkType"
      ) {
        editEvents.set(id, { kind: "UpdateWorkType", workType: updates });
      } else if (existingEvent.kind === "AddWorkType") {
        // If the work type is one we've added locally, just update the local object directly.
        editEvents.set(id, { kind: "AddWorkType", workType: updates });
      }

      setEditingClass((prev) => ({
        ...prev,
        workTypes: { ...prev.workTypes, [id]: updates },
      }));
    },
    [editEvents]
  );

  const handleRemoveWorkType = React.useCallback(
    (id: string) => {
      setIsEditing(true);

      if (editEvents.has(id)) {
        editEvents.delete(id);
      } else {
        editEvents.set(id, { kind: "RemoveWorkType" });
      }

      setEditingClass((prev) => {
        const next = structuredClone(prev);
        delete next.workTypes[id];
        return next;
      });
    },
    [editEvents]
  );

  const handleNameChange = React.useCallback(
    (name: string) => {
      setIsEditing(true);

      // Update name events have a static id because they always overwrite previous name updates.
      editEvents.set("UpdateClassName", {
        kind: "UpdateClassName",
        name,
      });

      setEditingClass((prev) => ({ ...prev, name }));
    },
    [editEvents]
  );

  const classificationValidation = React.useCallback(() => {
    if (editingClass.name.length === 0) return false;
    return true;
  }, [editingClass]);

  const saveOrCreate = React.useCallback(() => {
    const safe = classificationValidation();
    if (!safe) return;
    handleSave(editingClass, editEvents).then(() => {
      editEvents.clear();
      setIsEditing(false);
    });
  }, [
    editEvents,
    editingClass,
    handleSave,
    classificationValidation,
    setIsEditing,
  ]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
          handleExit();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            className={clsx(
              classes.paper,
              classes.scrollable,
              classes.modalContentArea
            )}
          >
            <Box className={clsx(classes.flexColumn)}>
              <Typography variant="h6">{header}</Typography>
              <TextField
                className={classes.nameTextField}
                label="Name"
                variant="outlined"
                value={editingClass.name}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => handleNameChange(event.target.value)}
              />
              <NewGradeInput
                className={classes.gradeTable}
                grades={editingClass.grades}
                addGrade={handleAddGrade}
                removeGrade={handleRemoveGrade}
                editGrade={handleEditGrade}
              />
              <NewWorkTypeInput
                types={editingClass.workTypes}
                grades={editingClass.grades}
                addType={handleAddWorkType}
                updateType={handleUpdateWorkType}
                removeType={handleRemoveWorkType}
              />
              <ConfirmDenyButtonAction
                confirmText={creatingNew ? "Create" : "Save"}
                denyText={"Cancel"}
                confirmAction={saveOrCreate}
                denyAction={() =>
                  isEditing ? setCancelConfirmOpen(true) : handleExit()
                }
              />
            </Box>
            <ConfirmationModal
              open={cancelConfirmOpen}
              setOpen={setCancelConfirmOpen}
              onConfirm={() => {
                setCancelConfirmOpen(false);
                handleExit();
              }}
              onCancel={() => setCancelConfirmOpen(false)}
              message={
                "You have unsaved changes that will be lost. Are you sure you want to quit?"
              }
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
