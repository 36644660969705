import { JSONSchemaType } from "ajv";

export const objectIdSchema: JSONSchemaType<string> = {
  type: "string",
  maxLength: 24,
  minLength: 24,
};

export interface TimestampsMixin {
  createdAt: Date;
  updatedAt: Date;
}

export interface ObjectIdMixin {
  _id: string;
}

export const auth0IdSchema: JSONSchemaType<string> = {
  type: "string",
  // Yea... this is the best we can do for now.
  minLength: 1,
};
