import {
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  TextField,
} from "@material-ui/core";
import React from "react";
import { clsx } from "../../clsx";
import {
  DropdownSelectorOption,
  IGrade,
  IWorkType,
} from "../../common/interfaces";
import {
  AlternateID,
  Action,
  WithIndex,
  AlternateFactory,
  OverrideScript,
} from "../../common/Script";
import Toaster, { ToastType } from "../../common/Toaster";
import {
  CancelOutlinedIcon,
  DeleteIcon,
  EditIcon,
  SaveOutlinedIcon,
} from "../../icons";
import {
  makeAlternate,
  SimpleActionTable,
} from "../Display/Table/SimpleActionTable";
import DropdownSelect, { makeDropdownOptions } from "./DropdownSelect";
import useModal from "../../hooks/useModal";
import HeaderWithRightSection from "../Display/HeaderWithRightSection";
import Column from "../Display/Column";
import Row from "../Display/Row";

export interface NewWorkTypeInputProps extends Partial<HTMLElement> {
  types: { [uuid: string]: IWorkType };
  grades: { [uuid: string]: IGrade };
  addType: (type: IWorkType) => void;
  updateType: (id: string, updates: IWorkType) => void;
  removeType: (id: string) => void;
}

export function NewWorkTypeInput(props: NewWorkTypeInputProps): JSX.Element {
  const { types, grades, addType, updateType, removeType } = props;

  const { modal: NewWorkTypeModal, toggle } = useModal();

  const [newTypeName, setNewTypeName] = React.useState<string>("");
  const [newTypeGradeId, setNewTypeGrade] = React.useState<string>("");
  const [newTypeRequireStudents, setNewTypeRequireStudents] =
    React.useState<boolean>(false);

  const resetNewType = () => {
    setNewTypeGrade("");
    setNewTypeName("");
    setNewTypeRequireStudents(false);
  };
  const [currentAlternate, setCurrentAlternate] = React.useState<AlternateID>({
    alternate: "",
    index: -1,
  });
  const [error, setError] = React.useState<boolean>(false);

  const onNameInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setError(false);
      setNewTypeName(event.target.value);
    },
    [setNewTypeName]
  );

  const onGradeInputChange = React.useCallback(
    (option: DropdownSelectorOption) => {
      setError(false);
      setNewTypeGrade(option.value);
    },
    [setNewTypeGrade]
  );

  const startEdit = React.useCallback<Action<{ id: string; type: IWorkType }>>(
    (typeWithIndex: WithIndex<{ id: string; type: IWorkType }>) => {
      setCurrentAlternate(makeAlternate("edit", typeWithIndex.index));
      setNewTypeGrade(typeWithIndex.type.grade);
      setNewTypeName(typeWithIndex.type.name);
      setNewTypeRequireStudents(typeWithIndex.type.requireStudents);
    },
    [setNewTypeGrade, setNewTypeName, setNewTypeRequireStudents]
  );

  const stopEdit = React.useCallback(() => {
    setCurrentAlternate(makeAlternate("", -1));
    resetNewType();
  }, []);

  const sortedWorkTypes = React.useMemo(() => {
    return Object.entries(types)
      .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      .map(([id, type]) => ({ id, type }));
  }, [types]);

  const sortedGrades = React.useMemo(() => {
    return Object.entries(grades).sort(([, a], [, b]) =>
      a.name.localeCompare(b.name)
    );
  }, [grades]);

  const sortedGradeOptions = React.useMemo(() => {
    return makeDropdownOptions(
      sortedGrades.map(([id, grade]) => ({ id, name: grade.name })),
      "name",
      "id"
    );
  }, [sortedGrades]);

  const getTypeNameEditComponent = React.useCallback<
    AlternateFactory<{ id: string; type: IWorkType }>
  >(() => {
    return (
      <TextField
        className={clsx()}
        variant="outlined"
        value={newTypeName}
        onChange={onNameInputChange}
      />
    );
  }, [onNameInputChange, newTypeName]);

  const getTypeGradeEditComponent = React.useCallback<
    AlternateFactory<{ id: string; type: IWorkType }>
  >(() => {
    return (
      <DropdownSelect
        className={clsx()}
        value={{
          label: newTypeGradeId in grades ? grades[newTypeGradeId].name : "",
          value: newTypeGradeId,
        }}
        handleChange={(ns) => {
          if (ns) onGradeInputChange(ns as DropdownSelectorOption);
        }}
        options={sortedGradeOptions}
      />
    );
  }, [grades, newTypeGradeId, onGradeInputChange, sortedGradeOptions]);

  const getTypeRequireStudentsEditComponent = React.useCallback<
    AlternateFactory<{ id: string; type: IWorkType }>
  >(() => {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={newTypeRequireStudents}
            onChange={() => setNewTypeRequireStudents(!newTypeRequireStudents)}
            name="checkedB"
            color="primary"
          />
        }
        label={newTypeRequireStudents ? "Yes" : "No"}
      />
    );
  }, [setNewTypeRequireStudents, newTypeRequireStudents]);

  const save = React.useCallback(
    (id: string) => {
      if (error) return;
      updateType(id, {
        name: newTypeName,
        grade: newTypeGradeId,
        requireStudents: newTypeRequireStudents,
      });
      stopEdit();
    },
    [
      newTypeGradeId,
      newTypeName,
      newTypeRequireStudents,
      error,
      updateType,
      stopEdit,
    ]
  );
  const getActionEditComponent = React.useCallback<
    AlternateFactory<{ id: string; type: IWorkType }>
  >(
    ({ id }) => {
      return (
        <>
          <IconButton onClick={() => save(id)}>
            <SaveOutlinedIcon />
          </IconButton>
          <IconButton onClick={stopEdit}>
            <CancelOutlinedIcon />
          </IconButton>
        </>
      );
    },
    [save, stopEdit]
  );

  const overrideScript: OverrideScript<{ id: string; type: IWorkType }> = [
    {
      id: "name",
      text: "Type Name",
      getField: ({ type }) => type.name,
      alternates: {
        edit: getTypeNameEditComponent,
      },
    },
    {
      id: "grade",
      text: "Grade",
      getField: ({ type }) =>
        type.grade in grades ? grades[type.grade].name : "",
      alternates: {
        edit: getTypeGradeEditComponent,
      },
    },
    {
      id: "requireStudents",
      text: "Require Students",
      align: "right",
      getField: ({ type }) => (type.requireStudents ? "Yes" : "No"),
      alternates: {
        edit: getTypeRequireStudentsEditComponent,
      },
    },
    {
      id: "action",
      text: "Action",
      isAction: true,
      align: "right",
      action: [startEdit, ({ id }) => removeType(id)],
      actionIcon: [
        <EditIcon key="edit" fontSize="small" />,
        <DeleteIcon key="delete" fontSize="small" />,
      ],
      alternates: {
        edit: getActionEditComponent,
      },
    },
  ];

  return (
    <Paper style={{ width: "100%" }}>
      <SimpleActionTable
        population={sortedWorkTypes}
        title={"Work Types"}
        action={({ id, type }) => {
          if (!type) return;
          removeType(id);
        }}
        actionIcon={<DeleteIcon />}
        makeDisplay={({ type }) => type.name}
        overrideScript={overrideScript}
        currentAlternate={currentAlternate}
        tableContainerProps={{
          height: "400px",
          overflowY: "auto",
        }}
      />
      <HeaderWithRightSection
        leftSection={<></>}
        rightSection={
          <Button
            style={{ margin: "5px" }}
            color="primary"
            variant="outlined"
            onClick={toggle}
          >
            New work type
          </Button>
        }
      />
      <NewWorkTypeModal>
        <Column gap="l" style={{ padding: "10px" }}>
          <TextField
            variant="outlined"
            label="Type Name"
            value={newTypeName}
            onChange={onNameInputChange}
            error={error}
          />
          <Row gap="l">
            <DropdownSelect
              forcePlaceholder
              value={{
                label:
                  newTypeGradeId in grades ? grades[newTypeGradeId].name : "",
                value: newTypeGradeId,
              }}
              handleChange={(ns) => {
                if (ns) onGradeInputChange(ns as DropdownSelectorOption);
              }}
              label={"Grade"}
              isSearchable={false}
              options={sortedGradeOptions}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={newTypeRequireStudents}
                  onChange={() =>
                    setNewTypeRequireStudents(!newTypeRequireStudents)
                  }
                  name="requiredStudents"
                  color="primary"
                />
              }
              label={"Require Students"}
            />
          </Row>

          <Row gap="l">
            <Button
              onClick={() => {
                toggle();
                resetNewType();
              }}
              fullWidth
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (
                  Object.values(types)
                    .map((t) => t.name)
                    .includes(newTypeName)
                ) {
                  Toaster(
                    `Type '${newTypeName}' cannot be duplicated.`,
                    ToastType.error
                  );
                  return;
                }
                addType({
                  name: newTypeName,
                  grade: newTypeGradeId,
                  requireStudents: newTypeRequireStudents,
                });
                resetNewType();
                toggle();
              }}
              fullWidth
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          </Row>
        </Column>
      </NewWorkTypeModal>
    </Paper>
  );
}
