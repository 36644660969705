import React from "react";
import { ISchool, ModalProps } from "../../common/interfaces";
import Toaster, { ToastType } from "../../common/Toaster";
import { useApi } from "../../api/useApi";
import { DefaultModal } from "../Display/DefaultModal";
import { SingleWorkItemEditorControls } from "./SingleWorkItemEditorControls";
import { useMutation } from "react-query";
import {
  ServiceRecord,
  UpdateServiceRecord,
} from "../../api/schemas/service-records/schema";

export interface SingleWorkItemEditorProps extends ModalProps {
  editingWorkItem: ServiceRecord | undefined;
  schools: ISchool[];
  invalidateServiceRecordQuery: () => void;
}

export function SingleWorkItemEditor(
  props: SingleWorkItemEditorProps
): JSX.Element {
  const {
    editingWorkItem,
    open,
    schools,
    setOpen,
    invalidateServiceRecordQuery,
  } = props;
  const api = useApi();

  const updateServiceRecordMutation = useMutation(api.serviceRecord.update, {
    onSuccess: () => {
      Toaster("Service record updated", ToastType.good);
      invalidateServiceRecordQuery();
    },
    onError: (error: { message: string }) => {
      Toaster(
        `Failed to update service record: ${error.message}`,
        ToastType.error
      );
    },
  });

  const close = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (editingWorkItem === undefined) return <></>;
  return (
    <DefaultModal onClose={close} open={open}>
      <SingleWorkItemEditorControls
        editingWorkItem={editingWorkItem}
        schools={schools}
        saveEdit={(params: { id: string; update: UpdateServiceRecord }) => {
          close();
          updateServiceRecordMutation.mutate(params);
        }}
        cancelEdit={close}
      />
    </DefaultModal>
  );
}
