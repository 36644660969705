import React, { useState } from "react";
import useForm from "../hooks/useForm";
import { useInterventions } from "../hooks/useInterventions";
import { useParams } from "react-router-dom";
import LoadingSplash from "./Display/LoadingSplash";
import {
  Paper,
  IconButton,
  TextField,
  Button,
  Tooltip,
} from "@material-ui/core";
import { EditIcon, GroupWorkIcon, DeleteIcon } from "../icons";
import Column from "./Display/Column";
import HeaderWithRightSection from "./Display/HeaderWithRightSection";
import IconText from "./Display/IconText";
import Row from "./Display/Row";
import SectionHeaderText from "./Display/SectionHeaderText";
import BackButton from "./Input/BackButton";
import { useHistory } from "react-router-dom";
import useModal from "../hooks/useModal";
import { OverrideVerse } from "../common/Script";
import { SimpleActionTable } from "./Display/Table/SimpleActionTable";
import { Intervention } from "../api/schemas/interventions/schema";

export default function InterventionGroupPage() {
  const { groupId } = useParams<{
    groupId: string;
  }>();
  const {
    interventionGroups,
    interventions,
    updateGroup,
    deleteIntervention,
    addIntervention,
  } = useInterventions();
  const [refresh, setRefresh] = useState(0); // hacky force the page to reload
  const history = useHistory();
  const group = interventionGroups.find((group) => group._id === groupId);
  const interventionsInGroup = interventions.filter((intervention) =>
    group?.interventionIds.includes(intervention._id)
  );
  const { modal: EditTitleModal, toggle: toggleEditTitleModal } = useModal();
  const { modal: AddInterventionModal, toggle: toggleAddInterventionModal } =
    useModal();
  const titleForm = useForm({
    initialValues: {
      title: group?.title ?? "",
    },
  });
  const interventionForm = useForm({
    initialValues: {
      text: "",
    },
  });

  const overrideCells: OverrideVerse<Intervention>[] = [
    {
      id: "text",
      text: "Text",
      getField: (intervention) => intervention.text,
    },
    {
      id: "action",
      text: "Action",
      isAction: true,
      align: "right",
      action: [
        (intervention) => {
          deleteIntervention(intervention);
        },
      ],
      actionIcon: [<DeleteIcon key="delete" fontSize="small" />],
    },
  ];

  if (!group) return <LoadingSplash />;

  return (
    <Paper>
      <HeaderWithRightSection
        style={{ padding: "10px" }}
        leftSection={
          <Row>
            <BackButton onClick={() => history.goBack()} />
            <IconText
              style={{ alignSelf: "center", paddingLeft: "5px" }}
              variant="h5"
              icon={
                <GroupWorkIcon fontSize={"large"} style={{ margin: "auto" }} />
              }
              text={group.title}
            />
            <IconButton onClick={toggleEditTitleModal}>
              <Tooltip title="Edit group title">
                <EditIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </Row>
        }
      />
      <Column gap="m" style={{ padding: "10px" }}>
        <SimpleActionTable
          population={interventionsInGroup}
          title={"Interventions"}
          overrideScript={overrideCells}
          tableContainerProps={{
            height: "400px",
            overflowY: "auto",
          }}
        />
        <HeaderWithRightSection
          leftSection={<></>}
          rightSection={
            <Button
              onClick={toggleAddInterventionModal}
              color="primary"
              variant="outlined"
            >
              Add intervention
            </Button>
          }
        />
      </Column>
      <EditTitleModal>
        <form
          onSubmit={titleForm.injectValues(async (values) => {
            updateGroup(group, { title: values.title });
            toggleEditTitleModal();
          })}
          onReset={titleForm.injectValues(() => {
            toggleEditTitleModal();
          })}
        >
          <Column style={{ padding: "10px" }} gap="s">
            <SectionHeaderText>Edit Name</SectionHeaderText>
            <TextField
              label="Title"
              variant="outlined"
              {...titleForm.register("title")}
            />
            <Row gap="l">
              <Button
                type="reset"
                fullWidth
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Row>
          </Column>
        </form>
      </EditTitleModal>
      <AddInterventionModal>
        <form
          onSubmit={interventionForm.injectValues((values) => {
            addIntervention({
              text: values.text,
              groupId: group._id,
            });
            setRefresh(refresh + 1);
            toggleAddInterventionModal();
            interventionForm.reset();
          })}
          onReset={interventionForm.injectValues(() => {
            toggleAddInterventionModal();
            interventionForm.reset();
          })}
        >
          <Column style={{ padding: "10px" }} gap="s">
            <SectionHeaderText>Add intervention</SectionHeaderText>
            <TextField
              label="Text"
              variant="outlined"
              multiline
              minRows={2}
              maxRows={10}
              {...interventionForm.register("text")}
            />
            <Row gap="l">
              <Button
                type="reset"
                fullWidth
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Row>
          </Column>
        </form>
      </AddInterventionModal>
    </Paper>
  );
}
