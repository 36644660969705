import React from "react";
import Column from "../components/Display/Column";
import { Paper } from "@material-ui/core";
import SectionHeaderText from "../components/Display/SectionHeaderText";
import { StudentServiceDetailsSearchControls } from "../components/StudentServiceDetailsSearchModal";

export default function ExportsView() {
  return (
    <Paper style={{ padding: "10px", height: "80dvh" }}>
      <SectionHeaderText>Export Options</SectionHeaderText>
      <Column style={{ maxWidth: "400px" }}>
        <StudentServiceDetailsSearchControls />
      </Column>
    </Paper>
  );
}
