import { Container, TextField } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import { ISchool, IStudent } from "../common/interfaces";
import Toaster, { ToastType } from "../common/Toaster";
import { copy, hasProp } from "../common/Utils";
import ConfirmationModal from "./ConfirmationModal";
import { ConfirmDenyButtonAction } from "./Input/ConfirmDenyButtonAction";
import { NewStudentInput } from "./Input/NewStudentInput";

interface ISchoolEditModalControlsProps {
  school: ISchool | undefined;
  handleExit: () => void;
  handleSave: (newSchool: ISchool) => void;
}

export function SchoolEditModalControls(
  props: ISchoolEditModalControlsProps
): JSX.Element {
  const { school, handleExit, handleSave } = props;

  const [cancelConfirmOpen, setCancelConfirmOpen] =
    React.useState<boolean>(false);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const [nameError, setNameError] = React.useState<boolean>(false);
  const [addressError, setAddressError] = React.useState<boolean>(false);
  const [invoicePrefixError, setInvoicePrefixError] =
    React.useState<boolean>(false);

  const creatingNew = school === undefined;

  const [editingSchool, setEditingSchool] = React.useState<ISchool>(
    copy(school)
  );

  const setAttribute = React.useCallback(
    (key: keyof ISchool, value: unknown) => {
      if (!editingSchool) return;
      setEditingSchool((prev: ISchool) => ({ ...prev, [key]: value }));
    },
    [editingSchool]
  );

  const handleNameChange = React.useCallback(
    (incoming: string) => {
      setIsEditing(true);
      setAttribute("name", incoming);
    },
    [setAttribute]
  );

  const handleAddressChange = React.useCallback(
    (incoming: string) => {
      setIsEditing(true);
      setAttribute("address", incoming);
    },
    [setAttribute]
  );

  const handleAddStudent = React.useCallback(
    (incoming: IStudent) => {
      setIsEditing(true);
      setAttribute(
        "students",
        editingSchool.students
          ? [...editingSchool.students, incoming]
          : [incoming]
      );
    },
    [editingSchool, setAttribute]
  );

  const handleRemoveStudent = React.useCallback(
    (incoming: IStudent) => {
      setIsEditing(true);
      setAttribute("students", [
        ...editingSchool.students.filter((stud) => stud.name !== incoming.name),
      ]);
    },
    [editingSchool.students, setAttribute]
  );

  const handleInvoicePrefixChange = React.useCallback(
    (incoming: string) => {
      setIsEditing(true);
      setAttribute("invoicePrefix", incoming);
    },
    [setAttribute]
  );

  const saveOrCreate = React.useCallback(() => {
    if (editingSchool === undefined) {
      Toaster(
        "Something went wrong with the application, editingSchool is undefined. " +
          "Please contact administrator.",
        ToastType.error
      );
      return;
    }
    if (editingSchool.name.length === 0) setNameError(true);
    if (editingSchool.address.length === 0) setAddressError(true);
    if (editingSchool.invoicePrefix.length === 0) setInvoicePrefixError(true);

    if (!hasProp(editingSchool, "programs") || !editingSchool.programs) {
      editingSchool.programs = [];
    }
    if (!hasProp(editingSchool, "students") || !editingSchool.students) {
      editingSchool.students = [];
    }
    if (nameError || addressError || invoicePrefixError) return;
    handleSave(editingSchool);
    setIsEditing(false);
  }, [editingSchool, nameError, addressError, invoicePrefixError, handleSave]);

  const sortedStudents = React.useMemo(() => {
    return (
      editingSchool?.students?.sort((a, b) => a.name.localeCompare(b.name)) ??
      []
    );
  }, [editingSchool]);

  if (editingSchool === undefined) return <> </>;

  return (
    <Container style={{ width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <TextField
          label="Name"
          variant="outlined"
          value={editingSchool.name}
          onChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setNameError(false);
            handleNameChange(e.target.value);
          }}
          error={nameError}
          style={{ width: "100%" }}
        />
        <TextField
          label="Address"
          variant="outlined"
          value={editingSchool.address}
          onChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setAddressError(false);
            handleAddressChange(e.target.value);
          }}
          error={nameError}
          style={{ width: "100%" }}
        />
        <TextField
          label="Invoice Prefix"
          variant="outlined"
          value={editingSchool.invoicePrefix}
          onChange={(
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setInvoicePrefixError(false);
            handleInvoicePrefixChange(e.target.value);
          }}
          error={invoicePrefixError}
          style={{ width: "100%" }}
        />
        <NewStudentInput
          students={sortedStudents}
          addStudent={handleAddStudent}
          removeStudent={handleRemoveStudent}
        />

        <ConfirmDenyButtonAction
          confirmAction={saveOrCreate}
          confirmText={creatingNew ? "Create" : "Save"}
          denyAction={() =>
            isEditing ? setCancelConfirmOpen(true) : handleExit()
          }
          denyText={"Cancel"}
        />
      </div>

      <ConfirmationModal
        open={cancelConfirmOpen}
        setOpen={setCancelConfirmOpen}
        onConfirm={() => {
          setCancelConfirmOpen(false);
          handleExit();
        }}
        onCancel={() => setCancelConfirmOpen(false)}
        message={
          "You have unsaved changes that will be lost. Are you sure you want to quit?"
        }
      />
    </Container>
  );
}
