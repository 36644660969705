import { PropTypes, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import Row from "./Row";
import { IconType } from "../../icons";
import { Variant } from "@material-ui/core/styles/createTypography";

interface IconTextProps {
  icon: IconType;
  text: string;
  style?: React.CSSProperties;
  className?: string;
  variant?: Variant;
  align?: PropTypes.Alignment;
}

export default function IconText(props: IconTextProps): JSX.Element {
  return (
    <Fragment>
      <Row gap="s">
        {props.icon}
        <Typography
          style={props.style}
          className={props.className}
          variant={props.variant}
          align={props.align}
        >
          {props.text}
        </Typography>
      </Row>
    </Fragment>
  );
}
