import { DateTime } from "luxon";
import { useApi } from "../api/useApi";
import FileSaver from "file-saver";
import Toaster, { ToastType } from "../common/Toaster";

export function useStudentServiceDetails() {
  const api = useApi();

  return {
    downloadCSV: async ({
      name,
      start,
      end,
    }: {
      name: string;
      start: DateTime;
      end: DateTime;
    }) => {
      try {
        const text = await api.exports.studentServiceDetails.csv({
          studentName: name,
          startDate: start.toISO(),
          endDate: end.toISO(),
        });
        const fname =
          `ssd_${name.toLowerCase().split(" ").join("_")}_` +
          `${start.toISODate().replaceAll("-", "_")}_` +
          `${end.toISODate().replaceAll("-", "_")}` +
          ".csv";
        FileSaver.saveAs(new Blob([text]), fname);
        Toaster(
          `Downloaded student service details for ${name}`,
          ToastType.good
        );
        return true;
      } catch (e) {
        Toaster((e as Error).message, ToastType.error);
        return false;
      }
    },
  };
}
