import { makeStyles } from "@material-ui/core";
import React, { ReactNode } from "react";
import { clsx } from "../../clsx";
import { OrganizerProperties } from "../../common/interfaces";
import { gapSizeToPx } from "../../common/organizer";

interface ColumnProps extends OrganizerProperties {
  style?: React.CSSProperties;
  center?: boolean;
  scrollarea?: boolean;
}

const useStyles = makeStyles({
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  center: {
    flexGrow: 1,
    height: "100%",
  },
  scrollarea: {
    paddingTop: "10px",
    maxHeight: "60vh",
    overflowY: "scroll",
  },
});

export default function Column(
  props: React.PropsWithChildren<ColumnProps>
): JSX.Element {
  const styles = useStyles();
  const apply_center = (children: ReactNode) => {
    if (props.center) {
      return <div style={{ margin: "auto" }}>{children}</div>;
    }

    return children;
  };
  return (
    <div
      className={clsx({
        [styles.flexColumn]: true,
        [styles.center]: props.center,
        [styles.scrollarea]: props.scrollarea,
      })}
      style={Object.assign(
        {},
        props.style,
        props.gap ? { gap: gapSizeToPx(props.gap) } : {}
      )}
    >
      {apply_center(props.children)}
    </div>
  );
}
