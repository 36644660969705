import { Grid, Paper } from "@material-ui/core";
import React from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ITherapist } from "../common/interfaces";
import ConfirmationModal from "../components/ConfirmationModal";
import StaffTable from "../components/StaffTable";
import BasicView from "./BasicView";
import { TherapistContext } from "../context/TherapistContext";
import StaffEditModal from "../components/Modal/StaffEditModal";
import Toaster, { ToastType } from "../common/Toaster";
import { useApi } from "../api/useApi";
import { useHistory } from "react-router-dom";

export default function StaffOverview(): JSX.Element {
  const {
    therapists,
    selectedTherapist,
    setSelectedTherapist,
    triggerRefresh,
  } = React.useContext(TherapistContext);
  const api = useApi();
  const history = useHistory();

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    React.useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
  const [therapistToDelete, setTherapistToDelete] =
    React.useState<ITherapist>();

  const clearEditing = React.useCallback(() => {
    setSelectedTherapist(undefined);
    setEditModalOpen(false);
  }, [setSelectedTherapist]);

  const handleEdit = React.useCallback(
    (uid: string) => {
      if (!uid || uid === "") return;
      history.push(`/staff/${uid}`);
    },
    [history]
  );

  const handleSave = React.useCallback(
    (incoming: ITherapist) => {
      if (selectedTherapist === undefined) {
        api.therapist.add(incoming).then((res) => {
          triggerRefresh();
          if (res.status === 200) {
            Toaster(`${incoming.name} updated.`, ToastType.good);
          } else {
            res.json().then((data) => {
              Toaster(
                `Error: ${res.statusText} ${data.message}`,
                ToastType.error
              );
            });
          }
        });
      } else {
        api.therapist
          .update({ id: selectedTherapist._id, updateObj: incoming })
          .then((res) => {
            triggerRefresh();
            if (res.status === 200) {
              Toaster(`${incoming.name} updated.`, ToastType.good);
            } else {
              res.json().then((data) => {
                Toaster(
                  `Error: ${res.statusText} ${data.message}`,
                  ToastType.error
                );
              });
            }
          });
      }
      setEditModalOpen(false);
    },
    [api.therapist, selectedTherapist, triggerRefresh]
  );

  const handleExitEdit = React.useCallback(() => {
    clearEditing();
  }, [clearEditing]);

  const handleDelete = React.useCallback(
    (name: string) => {
      setTherapistToDelete(
        therapists.find((therapist) => therapist.name === name)
      );
      setDeleteConfirmationModalOpen(true);
    },
    [setTherapistToDelete, setDeleteConfirmationModalOpen, therapists]
  );

  const handleDeleteTherapist = React.useCallback(() => {
    if (!therapistToDelete) return;
    api.therapist.delete([therapistToDelete._id]).then((res) => {
      triggerRefresh();
      if (res.status === 200) {
        Toaster(`${therapistToDelete?.name} deleted.`, ToastType.good);
      } else {
        res.json().then((data) => {
          Toaster(`Error: ${res.statusText} ${data.message}`, ToastType.error);
        });
      }
    });
    setDeleteConfirmationModalOpen(false);
  }, [api.therapist, therapistToDelete, triggerRefresh]);

  const handleAdd = React.useCallback(() => {
    setEditModalOpen(true);
  }, []);

  return (
    <BasicView>
      <Grid item xs={12}>
        <Paper>
          <StaffTable
            therapists={therapists}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onAdd={handleAdd}
          />

          <ConfirmationModal
            open={deleteConfirmationModalOpen}
            setOpen={setDeleteConfirmationModalOpen}
            onConfirm={() => handleDeleteTherapist()}
            onCancel={() => setDeleteConfirmationModalOpen(false)}
            message={`Are you sure want to delete ${therapistToDelete?.name}?`}
          />
          <StaffEditModal
            open={editModalOpen}
            setOpen={setEditModalOpen}
            handleExit={handleExitEdit}
            therapist={selectedTherapist}
            handleSave={handleSave}
          />
        </Paper>
      </Grid>
    </BasicView>
  );
}
