import {
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { ChangeEvent } from "react";
import { clsx } from "../../clsx";
import { IGrade } from "../../common/interfaces";
import {
  Action,
  AlternateFactory,
  AlternateID,
  OverrideVerse,
  WithIndex,
} from "../../common/Script";
import Toaster, { ToastType } from "../../common/Toaster";
import {
  DeleteIcon,
  EditIcon,
  SaveOutlinedIcon,
  CancelOutlinedIcon,
} from "../../icons";
import {
  SimpleActionTable,
  makeAlternate,
} from "../Display/Table/SimpleActionTable";
import useForm from "../../hooks/useForm";
import useModal from "../../hooks/useModal";
import HeaderWithRightSection from "../Display/HeaderWithRightSection";
import Column from "../Display/Column";
import Row from "../Display/Row";

export interface NewGradeInputProps extends Partial<HTMLElement> {
  grades: { [uuid: string]: IGrade };
  addGrade: (grade: IGrade) => void;
  removeGrade: (id: string) => void;
  editGrade: (id: string, grade: IGrade) => void;
}

const useStyles = makeStyles({
  inputContainer: {
    display: "flex",
    flexDirection: "row",
  },
  textFieldColumn: {
    flex: "55%",
    margin: "10px",
  },
  gradeDropdownColumn: {
    flex: "40%",
    marginTop: "10px",
  },
  submitButtonColumn: {
    flex: "5%",
  },
  submitButton: {
    margin: "12px 0px 0px 0px",
  },
});

export function NewGradeInput(props: NewGradeInputProps): JSX.Element {
  const { grades, addGrade, removeGrade, editGrade } = props;
  const styles = useStyles();

  const gradeForm = useForm({
    initialValues: {
      name: "",
      rate: 0,
    },
  });

  const { modal: NewGradeModal, toggle } = useModal();

  const [currentAlternate, setCurrentAlternate] = React.useState<AlternateID>({
    alternate: "",
    index: -1,
  });
  const [editingGradeUUID, setEditingGradeUUID] = React.useState<
    string | undefined
  >(undefined);
  const [editingRate, setEditingRate] = React.useState<number>(
    editingGradeUUID ? grades[editingGradeUUID].rate : 0
  );

  const startEdit = React.useCallback<Action<{ id: string; grade: IGrade }>>(
    (typeWithIndex: WithIndex<{ id: string; grade: IGrade }>) => {
      setCurrentAlternate(makeAlternate("edit", typeWithIndex.index));
      setEditingGradeUUID(typeWithIndex.id);
      setEditingRate(typeWithIndex.grade.rate);
    },
    []
  );
  const stopEdit = React.useCallback(() => {
    setCurrentAlternate(makeAlternate("", -1));
    setEditingRate(0);
    setEditingGradeUUID(undefined);
  }, []);

  const edit = React.useCallback(() => {
    if (editingRate === 0) {
      return;
    }

    if (editingGradeUUID === undefined) {
      // Should never happen
      return;
    }

    editGrade(editingGradeUUID, {
      name: grades[editingGradeUUID].name,
      rate: editingRate,
    });

    stopEdit();
  }, [editGrade, editingGradeUUID, editingRate, grades, stopEdit]);

  const getActionEditComponent = React.useCallback<
    AlternateFactory<{ id: string; grade: IGrade }>
  >(() => {
    return (
      <>
        <IconButton onClick={edit}>
          <SaveOutlinedIcon />
        </IconButton>
        <IconButton onClick={stopEdit}>
          <CancelOutlinedIcon />
        </IconButton>
      </>
    );
  }, [edit, stopEdit]);

  const removeGradeAction = React.useCallback(
    ({ id, grade }) => {
      if (!grade) return;
      removeGrade(id);
    },
    [removeGrade]
  );

  const overrideCells: OverrideVerse<{ id: string; grade: IGrade }>[] = [
    {
      id: "name",
      text: "Grade Name",
      getField: ({ grade }) => grade.name,
    },
    {
      id: "rate",
      text: "Rate (USD/unit)",
      getField: ({ grade }) => `$${grade.rate}`,
      alternates: {
        edit: () => (
          <TextField
            className={clsx(styles.gradeDropdownColumn)}
            variant="outlined"
            type="number"
            inputProps={{
              min: 0,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(
              e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setEditingRate(parseFloat(e.target.value));
            }}
            value={editingRate}
          />
        ),
      },
    },
    {
      id: "action",
      text: "Action",
      isAction: true,
      align: "right",
      action: [startEdit, removeGradeAction],
      actionIcon: [
        <EditIcon key="edit" fontSize="small" />,
        <DeleteIcon key="delete" fontSize="small" />,
      ],
      alternates: {
        edit: getActionEditComponent,
      },
    },
  ];

  return (
    <Paper style={{ width: "100%" }}>
      <SimpleActionTable
        population={Object.entries(grades).map(([id, grade]) => ({
          id,
          grade,
        }))}
        title={"Grade"}
        action={removeGradeAction}
        actionIcon={<DeleteIcon />}
        makeDisplay={({ grade }) => grade.name}
        overrideScript={overrideCells}
        currentAlternate={currentAlternate}
        tableContainerProps={{
          height: "400px",
          overflowY: "auto",
        }}
      />
      <HeaderWithRightSection
        leftSection={<></>}
        rightSection={
          <Button
            style={{ margin: "5px" }}
            onClick={toggle}
            color="primary"
            variant="outlined"
          >
            New grade
          </Button>
        }
      />
      <NewGradeModal>
        <form
          onSubmit={gradeForm.injectValues((values) => {
            if (
              Object.values(grades).find((grade) => grade.name === values.name)
            ) {
              Toaster("Grades can't have duplicate names", ToastType.error);
              return;
            }
            addGrade({ name: values.name, rate: values.rate });
          })}
          onReset={gradeForm.injectValues(() => {
            toggle();
          })}
        >
          <Column style={{ padding: "10px" }} gap="l">
            <TextField
              variant="outlined"
              label="Grade Name"
              {...gradeForm.register("name")}
            />
            <TextField
              variant="outlined"
              label="New Rate"
              type="number"
              inputProps={{
                min: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              {...gradeForm.register("rate")}
            />
            <Row gap="l">
              <Button
                type="reset"
                fullWidth
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Add
              </Button>
            </Row>
          </Column>
        </form>
      </NewGradeModal>
    </Paper>
  );
}
