import { useMemo } from "react";
import {
  CreateInterventionsGroup,
  InterventionGroup,
  UpdateInterventionsGroup,
} from "../api/schemas/interventions/groups/schema";
import { useApi } from "../api/useApi";
import Toaster, { ToastType } from "../common/Toaster";
import {
  CreateIntervention,
  Intervention,
} from "../api/schemas/interventions/schema";
import { useQuery, useQueryClient } from "react-query";

interface UseInterventionsProps {
  serviceUuid: string;
}

export function useInterventions(props?: UseInterventionsProps) {
  const api = useApi();

  const queryClient = useQueryClient();

  const { data: interventions } = useQuery({
    queryKey: api.intervention.list.queryKey,
    queryFn: () => api.intervention.list(),
  });

  const { data: interventionGroups } = useQuery({
    queryKey: api.intervention.group.list.queryKey,
    queryFn: () => api.intervention.group.list(),
  });

  const filteredGroups = useMemo(() => {
    if (!interventionGroups) return [];
    return interventionGroups.filter((group) =>
      props?.serviceUuid ? group.classUuid === props.serviceUuid : true
    );
  }, [interventionGroups, props?.serviceUuid]);

  const filteredInterventions = useMemo(() => {
    if (!interventions) return [];
    const interventionIds = filteredGroups.flatMap(
      (group) => group.interventionIds
    );

    return interventions.filter((intervention) =>
      interventionIds.includes(intervention._id)
    );
  }, [filteredGroups, interventions]);

  return useMemo(
    () => ({
      interventions: filteredInterventions,
      interventionGroups: filteredGroups,
      getGroups: (classUuid: string): InterventionGroup[] => {
        if (!interventionGroups) return [];
        return interventionGroups.filter(
          (group) => group.classUuid === classUuid
        );
      },
      addGroup: async (group: CreateInterventionsGroup) => {
        const res = await api.intervention.group.add(group);
        if (!res.ok) {
          Toaster(
            `Could not create group '${group.title}'. ${res.statusText}`,
            ToastType.error
          );

          return undefined;
        }

        Toaster(`Group '${group.title}' created successfully!`, ToastType.good);

        queryClient.invalidateQueries(api.intervention.group.list.queryKey);

        const data = await res.json();
        return data.data;
      },
      updateGroup: async (
        group: InterventionGroup,
        update: UpdateInterventionsGroup
      ) => {
        const res = await api.intervention.group.update({
          id: group._id,
          update,
        });
        if (!res.ok) {
          Toaster(
            `Could not update group '${group.title}'. ${res.statusText}`,
            ToastType.error
          );

          return;
        }

        Toaster(
          `Group '${update.title}' updated successfully!`,
          ToastType.good
        );

        queryClient.invalidateQueries(api.intervention.group.list.queryKey);
      },
      deleteGroup: async (group: InterventionGroup) => {
        if (group.interventionIds.length > 0) {
          Toaster(
            `Cannot delete group, '${group.title}' still has interventions associated with it.`,
            ToastType.error
          );

          return;
        }

        const res = await api.intervention.group.delete({ id: group._id });
        if (!res.ok) {
          Toaster(
            `Could not delete group '${group.title}'. ${res.statusText}`,
            ToastType.error
          );

          return;
        }

        Toaster(`Group '${group.title}' deleted successfully!`, ToastType.good);

        queryClient.invalidateQueries(api.intervention.group.list.queryKey);
      },
      addIntervention: async (create: CreateIntervention) => {
        const res = await api.intervention.add(create);
        if (!res.ok) {
          Toaster(
            `Could not create intervention. ${res.statusText}`,
            ToastType.error
          );

          return;
        }

        Toaster(`Intervention created successfully!`, ToastType.good);

        queryClient.invalidateQueries(api.intervention.list.queryKey);
        queryClient.invalidateQueries(api.intervention.group.list.queryKey);
      },
      getIntervention: (id: string) => {
        return filteredInterventions.find(
          (intervention) => intervention._id === id
        );
      },
      deleteIntervention: async (intervention: Intervention) => {
        const res = await api.intervention.delete({
          id: intervention._id,
        });
        if (!res.ok) {
          Toaster(
            `Could not delete intervention. ${res.statusText}`,
            ToastType.error
          );

          return;
        }

        Toaster(`Intervention deleted successfully!`, ToastType.good);

        queryClient.invalidateQueries(api.intervention.list.queryKey);
        queryClient.invalidateQueries(api.intervention.group.list.queryKey);
      },
    }),
    [
      api.intervention,
      filteredGroups,
      filteredInterventions,
      interventionGroups,
      queryClient,
    ]
  );
}
