import { TextField } from "@material-ui/core";
import React, { ChangeEvent } from "react";

export interface UnitSelectorProps {
  label: string;
  value: number;
  onChange: (newValue: number) => void;
}

export function UnitSelector(props: UnitSelectorProps): JSX.Element {
  const { label, value, onChange } = props;
  return (
    <TextField
      label={label}
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      value={value}
      variant="outlined"
      required
      onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e?.target?.value) onChange(parseInt(e.target.value));
      }}
    />
  );
}
