import {
  Button,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React from "react";

import { useHistory, useParams } from "react-router-dom";
import { NewGradeInput } from "./Input/NewGradeInput";
import { NewWorkTypeInput } from "./Input/NewWorkTypeInput";
import useService from "../hooks/useService";
import HeaderWithRightSection from "./Display/HeaderWithRightSection";
import BackButton from "./Input/BackButton";
import Row from "./Display/Row";
import IconText from "./Display/IconText";
import { EditIcon, SettingsIcon } from "../icons";
import LoadingSplash from "./Display/LoadingSplash";
import useModal from "../hooks/useModal";
import Column from "./Display/Column";
import SectionHeaderText from "./Display/SectionHeaderText";
import useForm from "../hooks/useForm";
import InterventionsTable from "./InterventionsTable";
interface IndividualServicePageProps {}

export default function IndividualServicePage(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: IndividualServicePageProps
) {
  const { id: uuid } = useParams<{ id: string }>();
  const history = useHistory();
  const service = useService({ uuid });

  const nameForm = useForm({
    initialValues: { name: service?.service?.name ?? "" },
  });

  const { modal: EditNameModal, toggle: toggleEditNameModal } = useModal();

  if (service === undefined || !service.service || uuid === undefined) {
    return <LoadingSplash />;
  }

  service;

  return (
    <Paper>
      <HeaderWithRightSection
        style={{ padding: "10px" }}
        leftSection={
          <Row>
            <BackButton onClick={() => history.push("/services")} />
            <IconText
              style={{ alignSelf: "center", paddingLeft: "5px" }}
              variant="h5"
              icon={
                <SettingsIcon fontSize={"large"} style={{ margin: "auto" }} />
              }
              text={service.service.name}
            />
            <IconButton onClick={toggleEditNameModal}>
              <Tooltip title="Edit service name">
                <EditIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </Row>
        }
      />
      <Column gap="m" style={{ padding: "10px" }}>
        <InterventionsTable serviceUuid={uuid} />
        <NewGradeInput
          grades={service.service.grades}
          addGrade={(grade) => service.addGrade(grade, uuid)}
          removeGrade={(id) => service.removeGrade(uuid, id)}
          editGrade={(id, grade) => service.updateGrade(grade, uuid, id)}
        />
        <NewWorkTypeInput
          types={service.service.workTypes}
          grades={service.service.grades}
          addType={(type) => service.addType(type, uuid)}
          updateType={(id, updates) => service.updateType(id, updates, uuid)}
          removeType={(id) => service.removeType(id, uuid)}
        />
      </Column>
      <EditNameModal>
        <form
          onSubmit={nameForm.injectValues(async (values) => {
            const success = await service.updateClass(
              { name: values.name },
              uuid
            );
            if (success) {
              toggleEditNameModal();
              nameForm.reset();
            }
          })}
          onReset={nameForm.injectValues(() => {
            toggleEditNameModal();
          })}
        >
          <Column style={{ padding: "10px" }} gap="s">
            <SectionHeaderText>Edit Name</SectionHeaderText>
            <TextField
              label="Name"
              variant="outlined"
              {...nameForm.register("name")}
            />
            <Row gap="l">
              <Button
                type="reset"
                fullWidth
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Row>
          </Column>
        </form>
      </EditNameModal>
    </Paper>
  );
}
