import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  ITimesheetRecordTableRow,
  UpdateType,
  ITimesheetRecord,
  ITherapist,
  IDateRange,
} from "../common/interfaces";
import Toaster, { ToastType } from "../common/Toaster";
import { TimesheetContext } from "../context/TimesheetContext";
import {
  ArrowDownwardIcon,
  CancelOutlinedIcon,
  DescriptionIcon,
  EditIcon,
  MenuIcon,
  SaveOutlinedIcon,
  TimerOutlinedIcon,
} from "../icons";
import {
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  PaperProps,
  Popover,
  Switch,
  Typography,
} from "@material-ui/core";
import UserTimesheetSubmissionFormModal from "./UserTimesheetFormModal";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { prettyDuration } from "../common/DateFormat";
import BasicDateTimePicker from "./BasicDateTimePicker";
import { Delete } from "@material-ui/icons";
import {
  AlternateID,
  AlternateFactory,
  Action,
  WithIndex,
  OverrideScript,
} from "../common/Script";
import { makeAlternate } from "./Display/Table/SimpleActionTable";
import SortableTable from "./Display/Table/SortableTable";
import { useApi } from "../api/useApi";
import { createLogger } from "./Logging/Logging";
import { BoldNumberDisplay } from "./Display/BoldNumberDisplay";
import {
  buildTimeSheetCSV,
  downloadCSV,
  getPeriodDateStringReadable,
  isContentHTML,
  isContentJSON,
  toInterval,
} from "../common/Utils";
import { UserContext } from "../context/UserContext";
import { UnitSelector } from "./Input/UnitSelector";
import { DateTime } from "luxon";
import { formatISO } from "../common/DateUtils";
import HeaderWithRightSection from "./Display/HeaderWithRightSection";
import Column from "./Display/Column";
import IconText from "./Display/IconText";
import SectionHeaderText from "./Display/SectionHeaderText";

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 400,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableRow: {
      "&$hover:hover": {
        backgroundColor: "blue",
      },
    },
    fabMargin: {
      marginTop: "5px",
      marginRight: "10px",
    },
    fabContainer: {
      display: "inline",
      float: "right",
      margin: "auto",
    },
    leftColumn: {
      flex: "40%",
    },
    rightColumn: {
      flex: "60%",
    },
    right: {
      marginLeft: "auto",
    },
    inline: {
      display: "flex",
    },
    densePaddingSwitch: {
      margin: "5px",
    },
    moreActionButton: {
      margin: "5px",
    },
    moreActionActionButton: {
      width: "100%",
      borderRadius: 0,
    },
  })
);

const logger = createLogger("AdminIndividualTimesheetTable");

interface AdminIndividualTimesheetTableProps {
  timesheetRecords: ITimesheetRecord[];
  selectedTherapist: ITherapist;
  showDelete?: boolean;
  hideStudents?: boolean;
  hideTherapist?: boolean;
  selectable?: boolean;
  tableContainerProps?: CSSProperties;
  paperProps?: PaperProps;
  columnsToHide?: string[];
  hideNewButton?: boolean;
  hideDataButton?: boolean;
  hideReportButton?: boolean;
  hideMenuButton?: boolean;
  start: DateTime;
  end: DateTime;
  defaultOption?: IDateRange;
  defaultDensePadding?: boolean;
  setStartDate: (start: DateTime | null) => void;
  setEndDate: (end: DateTime | null) => void;
}

export default function TimeSheetTable(
  props: AdminIndividualTimesheetTableProps
): JSX.Element {
  const api = useApi();
  const { triggerRefresh, deadline } = React.useContext(TimesheetContext);
  const { isAdmin } = React.useContext(UserContext);

  const { timesheetRecords } = props;
  const classes = useStyles();
  const [actionAnchor, setActionAnchor] = React.useState<HTMLElement | null>(
    null
  );
  const [dense, setDense] = React.useState<boolean>(
    props.defaultDensePadding ?? false
  );
  const [showTimesheetForm, setShowTimesheetForm] =
    React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<ITimesheetRecordTableRow>();
  const [currentAlternate, setCurrentAlternate] = React.useState<AlternateID>({
    alternate: "",
    index: -1,
  });

  const filteredRecords = React.useMemo(
    () =>
      timesheetRecords.filter(
        (tsr: ITimesheetRecord) =>
          tsr.therapistId === props.selectedTherapist.userId
      ),
    [props.selectedTherapist.userId, timesheetRecords]
  );

  const rowsForTimesheetTable = React.useMemo(() => {
    return filteredRecords
      .map((record: ITimesheetRecord) => {
        if (record._id === editing?._id) {
          record = editing;
        }
        const inDate = DateTime.fromISO(record.clockIn);
        const outDate = DateTime.fromISO(record.clockOut);
        const dateString = getPeriodDateStringReadable(inDate, outDate);
        return {
          _id: record._id,
          therapistId: record.therapistId,
          date: dateString,
          clockIn: inDate.toISO(),
          clockOut: outDate.toISO(),
          duration: prettyDuration(
            inDate,
            outDate,
            parseInt(record.break_units) ?? 0
          ),
          mileage: record.mileage,
          break_units: record.break_units,
        } as ITimesheetRecordTableRow;
      })
      .filter((ts) => {
        return (
          DateTime.fromISO(ts.clockIn).valueOf() >= props.start.valueOf() &&
          DateTime.fromISO(ts.clockOut).valueOf() <= props.end.valueOf()
        );
      });
  }, [editing, filteredRecords, props.end, props.start]);

  const setEditingAttribute = React.useCallback(
    (key: keyof ITimesheetRecordTableRow, value: unknown) => {
      if (editing === undefined) return;
      setEditing({ ...editing, [key]: value });
    },
    [editing]
  );

  const onDelete = React.useCallback(
    (row: ITimesheetRecordTableRow) => {
      const id = row._id;
      api.timesheet
        .delete({ idsArray: [id] })
        .then(
          async (res) =>
            await res
              .json()
              .then((json) => {
                if (res.status === 200) {
                  Toaster("Timesheet Deleted Successfully", ToastType.good);
                  triggerRefresh();
                } else {
                  Toaster(
                    `Could not delete timesheet: ${json.message}`,
                    ToastType.error
                  );
                  logger.error(
                    `ERROR ${res.status} => ${res.statusText}: ${json.message}`,
                    res
                  );
                }
              })
              .catch((e) => logger.error(e))
        )
        .catch((e) => logger.error(e));
    },
    [api.timesheet, triggerRefresh]
  );

  const handleDownloadTimeSheetData = React.useCallback(() => {
    downloadCSV(
      buildTimeSheetCSV(
        filteredRecords
          .filter((ts) => {
            return (
              DateTime.fromISO(ts.clockIn).valueOf() >= props.start.valueOf() &&
              DateTime.fromISO(ts.clockOut).valueOf() <= props.end.valueOf()
            );
          })
          .sort(
            (t1, t2) =>
              DateTime.fromISO(t1.clockIn).valueOf() -
              DateTime.fromISO(t2.clockIn).valueOf()
          ),
        props.selectedTherapist.name
      ),
      `${props.selectedTherapist.name.replaceAll(
        " ",
        "_"
      )}_TimeSheet_Report_${DateTime.local().toLocaleString()}`
    );
  }, [filteredRecords, props.end, props.selectedTherapist.name, props.start]);

  const handleDownLoadTimeSheetReport = React.useCallback(() => {
    return;
  }, []);

  const handleEditTimesheetRecord = React.useCallback(() => {
    if (editing === undefined) return;
    const clockIn = DateTime.fromISO(editing.clockIn, {
      zone: "America/New_York",
    });
    const clockOut = DateTime.fromISO(editing.clockOut, {
      zone: "America/New_York",
    });
    const editObject: UpdateType = {
      id: editing._id,
      updateObj: {
        _id: editing._id,
        clockIn: formatISO(clockIn),
        clockOut: formatISO(clockOut),
        mileage: editing.mileage,
        break_units: editing.break_units,
      } as ITimesheetRecord,
    };
    api.timesheet
      .update(editObject)
      .then(async (res) => {
        if (isContentJSON(res)) {
          await res.json().then((json) => {
            switch (res.status) {
              case 200:
                Toaster("Timesheet Edited Successfully", ToastType.good);
                triggerRefresh();
                break;
              case 423:
                Toaster(
                  "Unable to Edit: Payroll is locked for that range.",
                  ToastType.error
                );
                logger.error(
                  LOGGER_CATEGORY,
                  `ERROR ${res.status} => ${res.statusText}: ${json.message}`
                );
                break;
              case 406:
                Toaster(`${res.statusText} - ${json.message}`, ToastType.error);
                logger.error(
                  LOGGER_CATEGORY,
                  `ERROR ${res.status} => ${res.statusText}: ${json.message}`
                );
                break;
              default:
                Toaster(
                  `${res.statusText} - Unknown Exception: ${json.message}`,
                  ToastType.error
                );
                logger.error(
                  LOGGER_CATEGORY,
                  `ERROR ${res.status} => ${res.statusText}: ${json.message}`
                );
            }
            if (res.status === 200) {
            } else if (res.status === 406) {
            } else {
            }
          });
        } else if (isContentHTML(res)) {
          await res.text().then((t) => {
            Toaster(`Failed to edit timesheet: ${t}`, ToastType.error);
            logger.error(LOGGER_CATEGORY, `ERROR: ${t}`);
          });
        }
      })
      .catch((e) => logger.error(e));
    setEditing(undefined);
  }, [editing, api.timesheet, triggerRefresh]);

  const getClockInEditComponent = React.useCallback<
    AlternateFactory<ITimesheetRecordTableRow>
  >(() => {
    return (
      <BasicDateTimePicker
        value={DateTime.fromISO(editing?.clockIn ?? "")}
        onChange={(date) => setEditingAttribute("clockIn", date.toISO())}
      />
    );
  }, [editing?.clockIn, setEditingAttribute]);

  const getClockOutEditComponent = React.useCallback<
    AlternateFactory<ITimesheetRecordTableRow>
  >(() => {
    return (
      <BasicDateTimePicker
        value={DateTime.fromISO(editing?.clockOut ?? "")}
        onChange={(date) => setEditingAttribute("clockOut", date.toISO())}
      />
    );
  }, [editing?.clockOut, setEditingAttribute]);

  const getMilageEditComponent = React.useCallback<
    AlternateFactory<ITimesheetRecordTableRow>
  >(() => {
    return (
      <UnitSelector
        label={"Mileage"}
        value={+(editing?.mileage ?? 0)}
        onChange={(nv) => setEditingAttribute("mileage", nv.toString())}
      />
    );
  }, [editing?.mileage, setEditingAttribute]);

  const getBreakEditComponent = React.useCallback<
    AlternateFactory<ITimesheetRecordTableRow>
  >(() => {
    return (
      <UnitSelector
        label={"Break Units"}
        value={+(editing?.break_units ?? 0)}
        onChange={(nv) => setEditingAttribute("break_units", nv.toString())}
      />
    );
  }, [editing?.break_units, setEditingAttribute]);

  const handleCancel = React.useCallback(() => {
    setCurrentAlternate({
      alternate: "",
      index: -1,
    });
  }, [setCurrentAlternate]);

  const handleDelete = React.useCallback(
    (type: ITimesheetRecordTableRow) => {
      onDelete(type);
      handleCancel();
    },
    [handleCancel, onDelete]
  );

  const handleSave = React.useCallback(() => {
    handleEditTimesheetRecord();
    handleCancel();
  }, [handleCancel, handleEditTimesheetRecord]);

  const getActionEditComponent = React.useCallback<
    AlternateFactory<ITimesheetRecordTableRow>
  >(
    (type: ITimesheetRecordTableRow) => {
      return (
        <>
          <IconButton onClick={handleSave}>
            <SaveOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(type)}>
            <Delete />
          </IconButton>
          <IconButton onClick={handleCancel}>
            <CancelOutlinedIcon />
          </IconButton>
        </>
      );
    },
    [handleCancel, handleDelete, handleSave]
  );

  const startEdit = React.useCallback<Action<ITimesheetRecordTableRow>>(
    (type: WithIndex<ITimesheetRecordTableRow>) => {
      setCurrentAlternate(makeAlternate("edit", type.index));
      setEditing(type);
    },
    []
  );
  const script: OverrideScript<ITimesheetRecordTableRow> = React.useMemo(
    () => [
      {
        id: "DateTime",
        text: "Date",
        getField: (item: ITimesheetRecordTableRow) => item.date,
      },
      {
        id: "clockIn",
        text: "Time In",
        getField: (item: ITimesheetRecordTableRow) =>
          DateTime.fromISO(item.clockIn).toFormat("t"),
        alternates: {
          edit: getClockInEditComponent,
        },
      },
      {
        id: "clockOut",
        text: "Time Out",
        getField: (item: ITimesheetRecordTableRow) =>
          DateTime.fromISO(item.clockOut).toFormat("t"),
        alternates: {
          edit: getClockOutEditComponent,
        },
      },
      {
        id: "duration",
        text: "Total (Hours)",
        getField: (item: ITimesheetRecordTableRow) => item.duration,
      },
      {
        id: "therapistId",
        text: "Therapist ID",
        getField: (item: ITimesheetRecordTableRow) => item.therapistId,
      },
      {
        id: "mileage",
        text: "Mileage",
        getField: (item: ITimesheetRecordTableRow) => {
          return item.mileage;
        },
        alternates: {
          edit: getMilageEditComponent,
        },
      },
      {
        id: "break",
        text: "Break Units",
        getField: (item: ITimesheetRecordTableRow) => item.break_units,
        alternates: {
          edit: getBreakEditComponent,
        },
      },
      {
        isAction: true,
        id: "action",
        text: "Action",
        align: "right",
        action: startEdit,
        actionIcon: <EditIcon />,
        alternates: {
          edit: getActionEditComponent,
        },
        setDisabled: (member: ITimesheetRecordTableRow) =>
          DateTime.fromISO(member.clockIn).valueOf() <= deadline.valueOf() &&
          !isAdmin,
      },
    ],
    [
      deadline,
      getActionEditComponent,
      getBreakEditComponent,
      getClockInEditComponent,
      getClockOutEditComponent,
      getMilageEditComponent,
      isAdmin,
      startEdit,
    ]
  );

  const showActionMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) =>
      setActionAnchor(event.currentTarget),
    []
  );

  const filteredScript = React.useMemo(() => {
    return script.filter((verse) => !props.columnsToHide?.includes(verse.id));
  }, [props.columnsToHide, script]);

  const tableToolbar = React.useMemo(() => {
    const totalTimeSheet =
      rowsForTimesheetTable
        .map(
          (c) =>
            toInterval(
              DateTime.fromISO(c.clockIn),
              DateTime.fromISO(c.clockOut)
            )
              .toDuration("hours")
              .toObject().hours ?? 0
        )
        .reduce((accum, curr) => accum + curr, 0) -
      rowsForTimesheetTable
        .map((s) => parseInt(s.break_units))
        .reduce((p, c) => p + c, 0) *
        0.25;
    return (
      <HeaderWithRightSection
        leftSection={<SectionHeaderText>Time Sheets</SectionHeaderText>}
        rightSection={
          <BoldNumberDisplay
            label={"Timesheet"}
            num={totalTimeSheet}
            unit={"Hours"}
            variant={"h4"}
            compact
          />
        }
      />
    );
  }, [rowsForTimesheetTable]);

  const tableFooter = React.useMemo(() => {
    return (
      <HeaderWithRightSection
        leftSection={
          <FormControlLabel
            className={classes.densePaddingSwitch}
            control={
              <Switch
                checked={dense}
                color="primary"
                onChange={() => setDense(!dense)}
              />
            }
            label="Dense padding"
          />
        }
        rightSection={
          !props.hideMenuButton && (
            <Column center>
              <Button
                variant="outlined"
                color="primary"
                onClick={showActionMenu}
              >
                <MenuIcon />
              </Button>
            </Column>
          )
        }
      />
    );
  }, [classes.densePaddingSwitch, dense, props.hideMenuButton, showActionMenu]);

  return (
    <Paper elevation={3} {...props.paperProps}>
      <SortableTable
        label={"Time Sheets"}
        script={filteredScript}
        rows={rowsForTimesheetTable}
        tableContainerProps={props.tableContainerProps}
        currentAlternate={currentAlternate}
        altToolbar={tableToolbar}
        altFooter={tableFooter}
        noContentText={`No records found between ${props.start.toFormat(
          "LLL dd, y"
        )} and ${props.end.toFormat("LLL dd, y")}`}
        defaultSortKey="date"
        defaultSortOrder="asc"
        densePadding={dense}
      />
      <Popover
        anchorEl={actionAnchor}
        open={Boolean(actionAnchor)}
        onClose={() => setActionAnchor(null)}
      >
        <Column>
          {!props.hideNewButton && (
            <Button
              variant="text"
              size="medium"
              color="primary"
              onClick={() => {
                setShowTimesheetForm(true);
                setActionAnchor(null);
              }}
              className={classes.moreActionActionButton}
              fullWidth
            >
              <IconText
                variant="subtitle2"
                icon={<TimerOutlinedIcon />}
                text={"New Timesheet"}
              />
            </Button>
          )}
          {!props.hideDataButton && (
            <Button
              variant="text"
              size="medium"
              color="primary"
              onClick={() => {
                handleDownloadTimeSheetData();
                setActionAnchor(null);
              }}
              className={classes.moreActionActionButton}
              fullWidth
            >
              <IconText
                variant="subtitle2"
                icon={<ArrowDownwardIcon />}
                text={"Timesheet Data"}
              />
            </Button>
          )}

          {!props.hideReportButton && (
            <Button
              variant="text"
              size="medium"
              color="primary"
              onClick={() => handleDownLoadTimeSheetReport()}
              className={classes.moreActionActionButton}
              fullWidth
            >
              <DescriptionIcon />
              <Typography variant="subtitle2">Timesheet Report</Typography>
            </Button>
          )}
        </Column>
      </Popover>

      <UserTimesheetSubmissionFormModal
        open={showTimesheetForm}
        setOpen={setShowTimesheetForm}
        id={props.selectedTherapist?.userId}
      />
    </Paper>
  );
}

const LOGGER_CATEGORY = "AdminIndividualTimesheetTable";
