import React from "react";
import TokenContextProvider from "./context/TokenContext";
import LuxonUtils from "@date-io/luxon";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import SchoolContextProvider from "./context/SchoolContext";
import ServicesContextProvider from "./context/ServicesContext";
import TherapistContextProvider from "./context/TherapistContext";
import TimesheetContextProvider from "./context/TimesheetContext";
import UserContextProvider from "./context/UserContext";
import WorkItemContextProvider from "./context/WorkItemContext";
import { AppThemeContextProvider } from "./AppTheme";
import { ToastContainer } from "react-toastify";
import InterventionsContextProvider from "./context/InterventionsContext";
import InterventionGroupsContextProvider from "./context/InterventionGroupsContext";
import { QueryClient, QueryClientProvider } from "react-query";

interface ContextProps {
  token: string;
}
export default function AppContextProvider(
  props: React.PropsWithChildren<ContextProps>
): JSX.Element {
  const { token, children } = props;
  const queryClient = new QueryClient();

  return (
    <div id="app" className="d-flex flex-column h-100">
      <AppThemeContextProvider>
        <TokenContextProvider token={token}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <UserContextProvider>
              <ServicesContextProvider>
                <SchoolContextProvider>
                  <TherapistContextProvider>
                    <WorkItemContextProvider>
                      <TimesheetContextProvider>
                        <InterventionsContextProvider>
                          <InterventionGroupsContextProvider>
                            <QueryClientProvider client={queryClient}>
                              {children}
                            </QueryClientProvider>
                          </InterventionGroupsContextProvider>
                        </InterventionsContextProvider>
                      </TimesheetContextProvider>
                    </WorkItemContextProvider>
                  </TherapistContextProvider>
                </SchoolContextProvider>
              </ServicesContextProvider>
            </UserContextProvider>
          </MuiPickersUtilsProvider>
        </TokenContextProvider>
      </AppThemeContextProvider>
      <ToastContainer />
    </div>
  );
}
