import React from "react";
import { ITherapist } from "../common/interfaces";
import { SchoolContext } from "../context/SchoolContext";
import { ServicesContext } from "../context/ServicesContext";
import SortableTable from "./Display/Table/SortableTable";
import { OverrideScript } from "../common/Script";
import { AddIcon, VisibilityIcon } from "../icons";
import { isObjectEmpty } from "../common/Utils";
import HeaderWithRightSection from "./Display/HeaderWithRightSection";
import { IconButton } from "@material-ui/core";
import SectionHeaderText from "./Display/SectionHeaderText";

interface IClassificationTableProps {
  onEdit: (name: string) => void;
  onDelete: (name: string) => void;
  onAdd: () => void;
  therapists: ITherapist[];
}

export default function StaffTable(
  props: IClassificationTableProps
): JSX.Element {
  const { schools } = React.useContext(SchoolContext);
  const { classes } = React.useContext(ServicesContext);

  const formatAssignments = React.useCallback(
    (assignments: string[]) => {
      const prettyAssignmentsArray = schools
        .filter((school) => assignments.includes(school.id))
        .map((school) => `${school.name} ${school.invoicePrefix}`);
      const len = prettyAssignmentsArray.length;
      const prettyAssignmentString =
        len > 1 ? `${len} Assignments` : `${prettyAssignmentsArray[0] ?? ""}`;
      return prettyAssignmentString;
    },
    [schools]
  );

  const script: OverrideScript<ITherapist> = React.useMemo(() => {
    if (isObjectEmpty(classes)) return [];

    return [
      {
        id: "name",
        text: "Name",
        getField: (member: ITherapist) => member.name,
      },
      {
        id: "schools",
        text: "Assignments",
        getField: (member: ITherapist) => formatAssignments(member.schoolIds),
      },
      {
        id: "class",
        text: "Class",
        getField: (member: ITherapist) => {
          if (member.classId === "") return "";
          return classes[member.classId].name;
        },
      },
      {
        id: "grade",
        text: "Grade",
        getField: (member: ITherapist) => {
          if (member.classId === "" || member.gradeId === "") return "";
          return classes[member.classId].grades[member.gradeId].name;
        },
      },
      {
        id: "action",
        text: "Action",
        isAction: true,
        align: "right",
        action: (member: ITherapist) => props.onEdit(member.userId),
        actionIcon: <VisibilityIcon />,
      },
    ];
  }, [formatAssignments, props, classes]);

  if (props.therapists === undefined) return <></>;

  return (
    <SortableTable
      label="Staff"
      script={script}
      rows={props.therapists}
      defaultSortKey="name"
      defaultToDensePadding
      defaultRowsPerPage={10}
      altToolbar={
        <HeaderWithRightSection
          leftSection={<SectionHeaderText>Staff</SectionHeaderText>}
          rightSection={
            <IconButton color="primary" onClick={props.onAdd}>
              <AddIcon />
            </IconButton>
          }
        />
      }
    />
  );
}
