import React, { createContext, useEffect, useState } from "react";
import { useApi } from "../api/useApi";
import { createLogger } from "../components/Logging/Logging";
import { Intervention } from "../api/schemas/interventions/schema";
import { useLoad } from "../hooks/useLoad";

interface InterventionsContextProps {}
type InterventionsContextType = {
  interventions: Intervention[];
  reload: () => void;
};

const logger = createLogger("InterventionsContext");

export const InterventionsContext = createContext<InterventionsContextType>({
  interventions: [],
  reload: () => undefined,
});

export default function InterventionsContextProvider(
  props: React.PropsWithChildren<InterventionsContextProps>
): JSX.Element {
  const api = useApi();

  const [state, setState] = useState<InterventionsContextType>({
    reload: () => undefined,
    interventions: [],
  });

  const { load } = useLoad();

  useEffect(() => {
    load("interventions", api.intervention.list(), setState);
    setState((prev) => ({
      ...prev,
      reload: async () => {
        const status = await load(
          "intervetions",
          api.intervention.list(),
          setState
        );
        if (status) {
          logger.info("InterventionsContext loadded");
        } else {
          logger.info("Failed to load InterventionsContext");
        }
      },
    }));
  }, [api, load]);

  return (
    <InterventionsContext.Provider value={state}>
      {props.children}
    </InterventionsContext.Provider>
  );
}
