import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent } from "react";
import ConfirmationModal from "../components/ConfirmationModal";
import DropdownSelect from "../components/Input/DropdownSelect";
import WorkItemTable from "../components/WorkItemTable";
import { SchoolContext } from "../context/SchoolContext";
import BasicView from "./BasicView";
import FileSaver from "file-saver";
import { ServicesContext } from "../context/ServicesContext";
import { DropdownSelectorOption, ISchool } from "../common/interfaces";
import { TherapistContext } from "../context/TherapistContext";
import Toaster, { ToastType } from "../common/Toaster";
import { addLeadingZeros } from "../common/helpers";
import { buildWorkItemCSV, downloadCSV } from "../common/Utils";
import { WorkItemSummaryPane } from "../components/Display/WorkItemSummaryPane";
import { BillingActions } from "../components/Input/BillingActions";
import { useApi } from "../api/useApi";
import { createLogger } from "../components/Logging/Logging";
import LoadingSpinner from "../components/LoadingSpinner";
import { InvoiceContext } from "../context/InvoiceContext";
import { DateTime } from "luxon";
import { formatISO } from "../common/DateUtils";
import { FullScreenModal } from "../components/Display/FullScreenModal";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { SimpleDatePicker } from "../components/Input/SimpleDatePicker";
import Column from "../components/Display/Column";
import { useDateRanges } from "../hooks/useDateRanges";
import { useQuery, useQueryClient } from "react-query";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  dimmed: {
    color: theme.palette.text.disabled,
  },
  centeringColumn: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  button: {
    marginTop: "10px",
  },
  datePicker: {
    maxWidth: "165px",
  },
  someMargin: {
    margin: "8px",
  },
  inlineContainer: {
    display: "inline",
  },
  tableArea: {
    paddingTop: "0px",
  },
  flexContainer: {
    display: "flex",
  },
  left: {
    float: "left",
  },
  right: {
    marginLeft: "auto",
  },
  filterContainer: {
    display: "flex",
  },
  leftColumn: {
    flex: "75%",
    paddingBottom: "5px",
  },
  rightColumn: {
    flex: "25%",
  },
  fixedWidth: {},
  spinnerBack: {
    width: "80px",
    height: "80px",
    borderRadius: "100%",
    backgroundColor: theme.palette.primary.main,
  },
}));

const logger = createLogger("BillingView");

export default function BillingView(): JSX.Element {
  const schoolContext = React.useContext(SchoolContext);
  const settingsContext = React.useContext(ServicesContext);
  const therapistContext = React.useContext(TherapistContext);
  const invoiceContext = React.useContext(InvoiceContext);
  const api = useApi();

  const queryClient = useQueryClient();

  const { dateRanges } = useDateRanges();

  const [selectedStartDate, setSelectedStartDate] = React.useState<DateTime>(
    dateRanges.thisWeek.start
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState<DateTime>(
    dateRanges.thisWeek.end
  );

  const [selectedClassId, setSelectedClassId] = React.useState<
    string | undefined
  >(undefined);
  const [selectedSchool, setSelectedSchool] = React.useState<
    ISchool | undefined
  >(undefined);
  const [showInvoiced, setShowInvoiced] = React.useState<boolean>(false);

  const [ssrIdToDelete, setSsrIdToDelete] = React.useState<
    string | undefined
  >();

  const serviceRecordQueryParams = {
    dateWorked: {
      $gte: selectedStartDate.toISO(),
      $lte: selectedEndDate.toISO(),
    },
    schoolId: selectedSchool?.id,
    classId: selectedClassId,
    invoiceId: showInvoiced ? undefined : "null",
  };

  const { data, isLoading: areFilteredWorkItemsLoading } = useQuery({
    queryKey: api.serviceRecord.list.queryKey(serviceRecordQueryParams),
    queryFn: () => api.serviceRecord.list(serviceRecordQueryParams),
    placeholderData: [],
  });
  const filteredWorkItems = data ?? [];

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    React.useState<boolean>(false);
  const [invoiceConfirmationModalOpen, setInvoiceConfirmationModalOpen] =
    React.useState<boolean>(false);
  const [invoicePreviewModalOpen, setInvoicePreviewModalOpen] =
    React.useState<boolean>(false);
  const [invoiceNumber, setInvoiceNumber] = React.useState<string>("");
  const [invoiceDate, setInvoiceDate] = React.useState<DateTime>(
    DateTime.local()
  );
  const [invoiceNumberError, setInvoiceNumberError] =
    React.useState<boolean>(false);
  const [pdfLoading, setPDFLoading] = React.useState<boolean>(false);
  const [invoicePreview, setInvoicePreview] = React.useState<
    string | undefined
  >(undefined);
  const classes = useStyles();

  const openInvoiceModal = () => {
    let nextInvoiceId = "0";

    if (!selectedSchool) {
      Toaster("Please select a school", ToastType.error);
      return;
    }
    setInvoiceConfirmationModalOpen(true);

    api.invoice.number.get({ prefix: selectedSchool.invoicePrefix }).then(
      async (res) =>
        await res.json().then(async (i) => {
          if (res.status === 200) {
            nextInvoiceId = addLeadingZeros(i.id, 0);
            setInvoiceNumber(
              selectedSchool.invoicePrefix + "_" + nextInvoiceId
            );
          }
        })
    );
  };

  const downloadInvoice = React.useCallback(
    async (dryRun: boolean) => {
      setPDFLoading(true);

      if (!selectedSchool) {
        Toaster("Please select a school", ToastType.error);
        return;
      }

      if (!selectedClassId) {
        Toaster("Please select a service", ToastType.error);
        return;
      }

      const res = await api.invoice.get({
        schoolId: selectedSchool.id,
        classId: selectedClassId,
        startDate: formatISO(selectedStartDate),
        endDate: formatISO(selectedEndDate),
        invoiceId: invoiceNumber,
        isDryRun: dryRun.toString(),
        invoiceDate: invoiceDate.toFormat("MM/dd/yyyy"),
      });
      const data = await res.json();
      if (res.status === 200) {
        invoiceContext.makeInvoicesDirty();
        const encodedPDF = data.blob;
        if (dryRun) {
          setInvoicePreview(encodedPDF);
          return;
        }
        FileSaver.saveAs(
          "data:application/pdf;base64," + encodedPDF,
          `invoice_${invoiceNumber}.pdf`
        );
        Toaster(`Invoice ${invoiceNumber} downloaded`, ToastType.good);

        const schoolPrefix = selectedSchool.invoicePrefix;
        const incrementResponse = await api.invoice.number.increment({
          prefix: schoolPrefix,
        });

        if (incrementResponse.ok) {
          Toaster(`${schoolPrefix} Sequence Incrememented`, ToastType.info);
        } else {
          Toaster(
            `Failed to Increment ${schoolPrefix} Sequence`,
            ToastType.error
          );
          logger.error(`Failed to Increment ${schoolPrefix} Sequence`, {
            status: incrementResponse.status,
            message: incrementResponse.statusText,
            body: await incrementResponse.text(),
          });
        }
      } else {
        Toaster(
          `Failed to download invoice ${invoiceNumber}: ${data.message}`,
          ToastType.error
        );
        logger.error(
          `ERROR Code ${res.status}: ${res.statusText} => ${data.message}`,
          {
            res,
            data,
          }
        );
      }
      setPDFLoading(false);
    },
    [
      selectedSchool,
      selectedClassId,
      api.invoice,
      selectedStartDate,
      selectedEndDate,
      invoiceNumber,
      invoiceDate,
      invoiceContext,
    ]
  );

  const invoiceConfirmationMessageElement = React.useMemo(
    () =>
      pdfLoading || invoiceNumber === "" ? (
        <div style={{ marginLeft: "30px" }}>
          <div className={classes.spinnerBack}>
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <div>
          <Typography variant="h6">Generate Invoice</Typography>
          <br></br>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TextField
              label={"Invoice Number"}
              variant="outlined"
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setInvoiceNumber(e.target.value);
                setInvoiceNumberError(false);
              }}
              value={invoiceNumber}
              error={invoiceNumberError}
            />
            <div style={{ width: "10px" }}></div>
            <SimpleDatePicker
              label={"Invoice Date"}
              value={invoiceDate}
              onChange={(incoming: MaterialUiPickersDate) => {
                if (!incoming) {
                  logger.error("Incoming date of invoiceDate is undefined!");
                  return;
                }
                setInvoiceDate(incoming);
              }}
            />
          </div>
        </div>
      ),
    [
      pdfLoading,
      invoiceNumber,
      classes.spinnerBack,
      invoiceNumberError,
      invoiceDate,
    ]
  );

  const onInvoicePreviewDownload = React.useCallback(() => {
    downloadInvoice(false);
    setInvoicePreview(undefined);
    setPDFLoading(false);
    setInvoiceNumber("");
    setInvoicePreviewModalOpen(false);
  }, [downloadInvoice]);

  const onInvoicePreviewClose = React.useCallback(() => {
    setPDFLoading(false);
    setInvoiceNumber("");
    setInvoicePreviewModalOpen(false);
  }, []);

  const invoicePreviewElement = React.useMemo(() => {
    if (!selectedSchool) return <></>;
    if (!invoicePreviewModalOpen) return <></>;
    if (!invoicePreview) {
      downloadInvoice(true);
      return <></>;
    }
    return (
      <FullScreenModal
        onConfirm={onInvoicePreviewDownload}
        onClose={onInvoicePreviewClose}
      >
        <embed
          style={{ width: "100%", height: "100%" }}
          src={`data:application/pdf;base64,${invoicePreview}`}
        />
      </FullScreenModal>
    );
  }, [
    downloadInvoice,
    invoicePreview,
    invoicePreviewModalOpen,
    onInvoicePreviewClose,
    onInvoicePreviewDownload,
    selectedSchool,
  ]);

  const onInvoiceModalConfirm = React.useCallback(async () => {
    setInvoiceNumberError(false);
    if (invoiceNumber.length === 0) {
      setInvoiceNumberError(true);
      Toaster("Invoice Number Required", ToastType.error);
      return;
    }
    const response = await api.invoice.number.isUnique({
      invoiceNumber: invoiceNumber,
    });

    const body = await response.json();

    if (!response.ok) {
      logger.error(
        `ERROR Code ${response.status}: ${response.statusText} => ${body.message}`,
        {
          response,
          body,
        }
      );
      return;
    }

    if (!body) {
      setInvoiceNumberError(true);
      Toaster("Invoice Number needs to be unique", ToastType.error);
      return;
    }

    setInvoiceConfirmationModalOpen(false);
    setInvoicePreviewModalOpen(true);
  }, [invoiceNumber, api.invoice.number]);

  return (
    <BasicView>
      <WorkItemSummaryPane
        selectedSchool={selectedSchool}
        selectedTherapist={"All"}
        workItems={filteredWorkItems}
        start={selectedStartDate}
        end={selectedEndDate}
        numSchools={schoolContext.schools.length}
      />
      <BillingActions
        invoiceDisabled={
          !selectedSchool || !selectedClassId || filteredWorkItems.length === 0
        }
        invoiceAction={openInvoiceModal}
        csvAction={() =>
          downloadCSV(
            buildWorkItemCSV(
              filteredWorkItems,
              schoolContext.schools,
              therapistContext.therapists,
              settingsContext.classes
            ),
            `WorkItem_Report_${DateTime.local().toLocaleString()}`
          )
        }
        hideCSV
      />
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Column gap="m">
            <Column gap="s">
              <DropdownSelect
                label={"School"}
                value={{
                  label: selectedSchool
                    ? `${selectedSchool.name} ${selectedSchool.invoicePrefix}`
                    : "All",
                  value: undefined,
                }}
                handleChange={(change) => {
                  const schoolId = (change as DropdownSelectorOption | null)
                    ?.value;

                  if (schoolId) {
                    setSelectedSchool(
                      schoolContext.schools.find(
                        (school) => school.id === schoolId
                      )
                    );
                  } else {
                    // Skip the worst case ;)
                    setSelectedSchool(undefined);
                  }
                }}
                options={schoolContext.schools.map((school) => ({
                  label: `${school.name} ${school.invoicePrefix}`,
                  value: school.id,
                }))}
                // includeAll
                isClearable
              />
              <DropdownSelect
                value={{
                  label:
                    selectedClassId && settingsContext.classes[selectedClassId]
                      ? settingsContext.classes[selectedClassId].name
                      : "All",
                  value: undefined,
                }}
                label="Related Service"
                options={Object.entries(settingsContext.classes).map(
                  ([id, service]) => ({ label: service.name, value: id })
                )}
                handleChange={(change) => {
                  setSelectedClassId(
                    (change as DropdownSelectorOption | null)?.value
                  );
                }}
                // includeAll
                isClearable
              />
            </Column>
            <WorkItemTable
              rows={filteredWorkItems}
              areRowsLoading={areFilteredWorkItemsLoading}
              onDelete={(ssr) => {
                setSsrIdToDelete(ssr._id);
                queryClient.invalidateQueries(
                  api.serviceRecord.list.queryKey(serviceRecordQueryParams)
                );
                setDeleteConfirmationModalOpen(true);
              }}
              columnsToHide={[]}
              start={selectedStartDate}
              end={selectedEndDate}
              showInvoicedWorkItems={showInvoiced}
              setStartDate={(start) => setSelectedStartDate(start)}
              setEndDate={(end) => setSelectedEndDate(end)}
              setShowInvoicedWorkItems={(show) => setShowInvoiced(show)}
              listStudents
              defaultDensePadding
              invalidateServiceRecord={() =>
                queryClient.invalidateQueries(
                  api.serviceRecord.list.queryKey(serviceRecordQueryParams)
                )
              }
              paginationProps={{
                rowsPerPageOptions: [25, 50, 75],
                defaultRowsPerPage: 25,
              }}
            />

            {invoicePreviewModalOpen && invoicePreviewElement}
          </Column>
        </Paper>
      </Grid>
      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        onConfirm={() => {
          api.serviceRecord
            // @ts-expect-error ssrIdToDelete can be undefined, but the schema will catch it
            .delete({ id: ssrIdToDelete })
            .then(() => Toaster("Work item deleted", ToastType.good))
            .catch((e) => Toaster(e.message, ToastType.error));
        }}
        onCancel={() => setDeleteConfirmationModalOpen(false)}
      />
      <ConfirmationModal
        open={invoiceConfirmationModalOpen}
        setOpen={setInvoiceConfirmationModalOpen}
        onConfirm={onInvoiceModalConfirm}
        onCancel={() => {
          setPDFLoading(false);
          setInvoiceConfirmationModalOpen(false);
        }}
        message={invoiceConfirmationMessageElement}
        confirmText={"Create Draft"}
      />
    </BasicView>
  );
}
