import { Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import React from "react";

interface SectionHeaderTextProps {
  noWrap?: boolean;
  style?: React.CSSProperties;
  variant?: Variant;
}

export default function SectionHeaderText(
  props: React.PropsWithChildren<SectionHeaderTextProps>
): JSX.Element {
  return (
    <Typography
      style={
        props.style ?? {
          textAlign: "left",
          margin: "10px 12px",
        }
      }
      variant={props.variant || "h5"}
      id="section-header"
      component="div"
      noWrap={Boolean(props.noWrap)}
    >
      {props.children}
    </Typography>
  );
}
