import { JSONSchemaType } from "ajv";
import { auth0IdSchema, objectIdSchema } from "../utils";
import { ajv } from "..";

export interface ServiceRecord {
  _id: string;
  units: number;
  interventionIds: string[];
  dateWorked: string;
  // Indicates that a record was logged via the new service records API rather
  // than the legacy work items endpoints. When using this API, the front end
  // MUST log the start time as part of the `dateWorked` field.
  hasStartTime: boolean;
  classId: string;
  workTypeId: string;
  // The _id of the school. Stored as a string for backwards compatibility purposes.
  schoolId: string;
  // `schoolId` references can dangle but we still need to know which district
  // a record was submitted for. The endpoints will populate this field for
  // the client.
  schoolName: string;
  // This is the userId field in the therapists collection, NOT the _id field.
  therapistUserId: string;
  students: Student[];
  narrativeNote: string;
  dateLogged: string;
  invoiceId: string;
  // MM/DD/YYYY
  invoiceDate?: string;
  updatedAt?: string;
  createdAt?: string;
  className: string;
  workTypeName: string;
  // Version number. Added in the Dec 2024 when the back-end migrated to
  // mongoose. Records logged prior to that date do not have this field.
  __v?: number;
}

export interface Student {
  name: string;
  sasid: string;
}

const studentSchema: JSONSchemaType<Student> = {
  type: "object",
  properties: {
    name: { type: "string", minLength: 1 },
    sasid: { type: "string" },
  },
  required: ["name", "sasid"],
  additionalProperties: false,
};

export interface CreateServiceRecord {
  schoolId: string;
  dateWorked: string;
  workTypeId: string;
  classId: string;
  units: number;
  students: Student[];
  therapistUserId: string;
  narrativeNote: string;
  interventionIds: string[];
  hasStartTime: boolean;
}

export const createSchema: JSONSchemaType<CreateServiceRecord> = {
  type: "object",
  properties: {
    schoolId: objectIdSchema,
    dateWorked: { type: "string", format: "iso-date-time" },
    hasStartTime: { type: "boolean" },
    workTypeId: { type: "string", format: "uuid" },
    classId: { type: "string", format: "uuid" },
    units: { type: "integer" },
    students: { type: "array", items: studentSchema },
    therapistUserId: auth0IdSchema,
    narrativeNote: { type: "string", maxLength: 1000 },
    interventionIds: { type: "array", items: objectIdSchema },
  },
  required: [
    "schoolId",
    "dateWorked",
    "hasStartTime",
    "workTypeId",
    "classId",
    "units",
    "students",
    "therapistUserId",
    "interventionIds",
    "narrativeNote",
  ],
  additionalProperties: false,
};

export interface UpdateServiceRecord {
  dateWorked?: string;
  workTypeId?: string;
  units?: number;
  students?: Student[];
  narrativeNote?: string;
  interventionIds?: string[];
  hasStartTime?: boolean | null;
}

export const updateSchema: JSONSchemaType<UpdateServiceRecord> = {
  type: "object",
  properties: {
    dateWorked: { type: "string", format: "iso-date-time", nullable: true },
    hasStartTime: { type: "boolean", nullable: true },
    workTypeId: { type: "string", format: "uuid", nullable: true },
    units: { type: "integer", nullable: true },
    students: { type: "array", items: studentSchema, nullable: true },
    narrativeNote: { type: "string", maxLength: 1000, nullable: true },
    interventionIds: {
      type: "array",
      items: objectIdSchema,
      nullable: true,
    },
  },
  required: [],
  additionalProperties: false,
};

export interface ListServiceRecords {
  dateWorked?: {
    $gte?: string;
    $lte?: string;
  };
  schoolId?: string;
  classId?: string;
  therapistUserId?: string;
  invoiceId?: string | null;
}

export const listSchema: JSONSchemaType<ListServiceRecords> = {
  type: "object",
  properties: {
    dateWorked: {
      type: "object",
      properties: {
        $gte: { type: "string", format: "iso-date-time", nullable: true },
        $lte: { type: "string", format: "iso-date-time", nullable: true },
      },
      additionalProperties: false,
      nullable: true,
    },
    schoolId: { type: "string", nullable: true },
    classId: { type: "string", nullable: true },
    therapistUserId: { type: "string", nullable: true },
    invoiceId: { type: "string", nullable: true },
  },
  additionalProperties: false,
};

export const serviceRecordValidators = {
  validateList: ajv.compile(listSchema),
  validateCreate: ajv.compile(createSchema),
  validateUpdate: ajv.compile(updateSchema),
  validateStudent: ajv.compile(studentSchema),
};
