/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";

export default function useForm<T, K extends keyof T>({
  initialValues,
}: {
  initialValues: T;
}) {
  const [state, setState] = useState<T>(initialValues);

  const handleUpdate = useCallback((key: K, update: T[K]) => {
    setState((prev) => ({ ...prev, [key]: update }));
  }, []);

  const reset = useCallback(() => setState(initialValues), [initialValues]);

  return useMemo(
    () => ({
      register: (key: K) => ({
        id: key,
        value: state[key],
        onChange: (event: ChangeEvent) => {
          const target = event.target as unknown as {
            value: T[K];
          };
          const update =
            typeof state[key] === "number"
              ? Number(target.value)
              : target.value;

          handleUpdate(key, update as T[K]);
        },
      }),
      injectValues: (func: (values: T) => void) => {
        return (event: SyntheticEvent) => {
          event.preventDefault();
          func(state);
        };
      },
      reset,
    }),
    [handleUpdate, reset, state]
  );
}
