import React, { Fragment } from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { DateTime } from "luxon";

export interface BasicDateTimePickerProps {
  label?: string;
  value: DateTime;
  onChange: (newDate: DateTime) => void;
  style?: { [key: string]: string };
}

function BasicDateTimePicker(props: BasicDateTimePickerProps): JSX.Element {
  const { label, value, onChange, style } = props;

  return (
    <Fragment>
      <KeyboardDateTimePicker
        label={label}
        inputVariant="outlined"
        value={value}
        onChange={(date: DateTime | null) => {
          if (date) onChange(date);
        }}
        format="MM/dd/yyyy hh:mm a"
        style={style}
      />
    </Fragment>
  );
}

export default BasicDateTimePicker;
