import {
  CircularProgress,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";

interface NoTableContentProps {
  numCols?: number;
  text?: string;
  loading: boolean;
}

const useStyles = makeStyles({
  row: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "400px",
  },
  cell: {
    alignItems: "center",
  },
  center: {
    display: "flex",
    flexDirection: "row",
    margin: "10px auto",
  },
});

export function NoTableContent(props: NoTableContentProps): JSX.Element {
  const { numCols, text, loading } = props;
  const styles = useStyles();
  return (
    <TableRow className={styles.row}>
      <TableCell colSpan={numCols ?? 1} className={styles.cell}>
        {loading ? (
          <CircularProgress className={styles.center} />
        ) : (
          <Typography>{text ?? "No Content"}</Typography>
        )}
      </TableCell>
    </TableRow>
  );
}
