import React from "react";
import {
  IClassWithoutWorkTypesOrGrades,
  ServicesContextType,
  IClassification,
  IGrade,
  UuidResponse,
  IWorkType,
} from "../common/interfaces";
import { useIsMounted } from "../common/useIsMounted";
import { isContentJSON } from "../common/Utils";
import { createLogger } from "../components/Logging/Logging";
import { useApi } from "../api/useApi";
import { UserContext } from "./UserContext";

interface ServicesContextProps {}

const defaultState = {
  classes: {},
  isLoading: true,
  triggerRefresh: () => null,
};

export const ServicesContext =
  React.createContext<ServicesContextType>(defaultState);

const logger = createLogger("ServicesContext");

export default function ServicesContextProvider(
  props: React.PropsWithChildren<ServicesContextProps>
): JSX.Element {
  const isMounted = useIsMounted();
  const api = useApi();
  const [refreshTrigger, setRefreshTrigger] = React.useState<number>(0);
  const { isAdmin } = React.useContext(UserContext);
  const [settings, setSettings] = React.useState<ServicesContextType>({
    classes: {},
    isLoading: true,
    triggerRefresh: () => {
      setRefreshTrigger((prev) => prev + 1);
    },
  });

  async function unwrapResponse<T>(
    pendingResponse: Promise<Response>
  ): Promise<T> {
    const response = await pendingResponse;
    const responseJson = await response.json();

    if (!response.ok) {
      if ("message" in responseJson) {
        throw Error(responseJson.message);
      } else {
        throw Error(`Error code ${response.status}.`);
      }
    }

    return responseJson;
  }

  const syncSettingsWithServer = React.useCallback(async () => {
    const response = await unwrapResponse<{
      [uuid: string]: IClassification;
    }>(api.configuration.classes.get());

    if (isMounted.current) {
      setSettings((old) => ({ ...old, classes: response }));
    }
  }, [api.configuration.classes, isMounted]);

  const addClass = React.useCallback(
    async (clss: IClassWithoutWorkTypesOrGrades) => {
      return await unwrapResponse<UuidResponse>(
        api.configuration.classes.add(clss)
      );
    },
    [api.configuration.classes]
  );

  const updateClass = React.useCallback(
    async (updates: IClassWithoutWorkTypesOrGrades, classId: string) => {
      return await unwrapResponse<UuidResponse>(
        api.configuration.classes.update(updates, classId)
      );
    },
    [api.configuration.classes]
  );

  const removeClass = React.useCallback(
    async (classId: string) => {
      await unwrapResponse(api.configuration.classes.delete(classId));
    },
    [api.configuration.classes]
  );

  const addGradeToClass = React.useCallback(
    async (grade: IGrade, classId: string) => {
      return await unwrapResponse<UuidResponse>(
        api.configuration.classes.grades.add(grade, classId)
      );
    },
    [api.configuration.classes.grades]
  );

  const updateGradeInClass = React.useCallback(
    async (updates: IGrade, classId: string, gradeId: string) => {
      return await unwrapResponse<UuidResponse>(
        api.configuration.classes.grades.update(updates, classId, gradeId)
      );
    },
    [api.configuration.classes.grades]
  );

  const removeGradeFromClass = React.useCallback(
    async (classId: string, gradeId: string) => {
      await unwrapResponse(
        api.configuration.classes.grades.delete(classId, gradeId)
      );
    },
    [api.configuration.classes.grades]
  );

  const addWorkTypeToClass = React.useCallback(
    async (workType: IWorkType, classId: string) => {
      return await unwrapResponse<UuidResponse>(
        api.configuration.classes.workTypes.add(workType, classId)
      );
    },
    [api.configuration.classes.workTypes]
  );

  const updateWorkTypeInClass = React.useCallback(
    async (updates: IWorkType, classId: string, workTypeId: string) => {
      return await unwrapResponse<UuidResponse>(
        api.configuration.classes.workTypes.update(updates, classId, workTypeId)
      );
    },
    [api.configuration.classes.workTypes]
  );

  const removeWorkTypeFromClass = React.useCallback(
    async (classId: string, workTypeId: string) => {
      await unwrapResponse<UuidResponse>(
        api.configuration.classes.workTypes.delete(classId, workTypeId)
      );
    },
    [api.configuration.classes.workTypes]
  );

  React.useEffect(() => {
    setSettings((old) => ({
      ...old,
      syncSettingsWithServer,
      addClass,
      updateClass,
      removeClass,
      addGradeToClass,
      updateGradeInClass,
      removeGradeFromClass,
      addWorkTypeToClass,
      updateWorkTypeInClass,
      removeWorkTypeFromClass,
    }));
  }, [
    syncSettingsWithServer,
    isMounted,
    addClass,
    addGradeToClass,
    addWorkTypeToClass,
    removeClass,
    removeGradeFromClass,
    removeWorkTypeFromClass,
    updateClass,
    updateGradeInClass,
    updateWorkTypeInClass,
  ]);

  React.useEffect(() => {
    const call = async () => {
      try {
        switch (isAdmin) {
          case true:
            api.settings.admin
              .get()
              .then(
                async (res) =>
                  await res.json().then((j) => {
                    if (res.status === 200) {
                      if (isMounted.current)
                        setSettings((prev) => ({
                          ...prev,
                          ...(j as ServicesContextType),
                        }));
                    } else {
                      logger.error(
                        `ERROR Code ${res.status}: ${res.statusText} => ${j.message}`,
                        {
                          res,
                          j,
                        }
                      );
                    }
                  })
              )
              .catch((e) => logger.error(e));
            break;
          case false:
            api.settings.public
              .get()
              .then(async (res) => {
                if (isContentJSON(res)) {
                  await res.json().then((j) => {
                    if (res.status === 200) {
                      if (isMounted.current)
                        setSettings((prev) => ({
                          ...prev,
                          ...(j as ServicesContextType),
                        }));
                    } else {
                      logger.error(
                        `ERROR Code ${res.status}: ${res.statusText} => ${j.message}`,
                        {
                          res,
                          j,
                        }
                      );
                    }
                  });
                }
              })
              .catch((e) => logger.error(e));
            break;
          default:
            logger.fatal("SHOULD NEVER HAPPEN!");
        }
      } catch (error) {
        logger.error("ERROR", error);
      }
    };
    call();
    setSettings((prev) => ({ ...prev, isLoading: false }));
  }, [
    isAdmin,
    isMounted,
    refreshTrigger,
    api.settings.admin,
    api.settings.public,
  ]);

  React.useEffect(() => {
    logger.info(`loaded = ${!settings.isLoading}`);
  }, [settings.isLoading]);

  return (
    <ServicesContext.Provider value={settings}>
      {props.children}
    </ServicesContext.Provider>
  );
}
