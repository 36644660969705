import React from "react";
import { IDateRange, IWorkItem, IWorkItemContext } from "../common/interfaces";
import { TherapistContext } from "./TherapistContext";
import {
  DateRange,
  endOfLastWeek,
  endOfWeek,
  isContentJSON,
  startOfLastWeek,
  startOfWeek,
} from "../common/Utils";
import { filter } from "../common/Filtering";
import { useIsMounted } from "../common/useIsMounted";
import { useApi } from "../api/useApi";
import { createLogger } from "../components/Logging/Logging";
import { DateTime } from "luxon";
import { ServicesContext } from "./ServicesContext";
const defaultState: IWorkItemContext = {
  isLoading: true,
  workItems: [],
  periodWorkItems: [],
  filteredWorkItems: [],
  end: DateTime.local(),
  start: DateTime.local(),
  school: "All",
  workTypeId: "All",
  classId: "All",
  invoiceId: "",
  timeRanges: new Map<string, IDateRange>(),
  thisWeek: DateRange(
    "This Week",
    startOfWeek(DateTime.local()),
    endOfWeek(DateTime.local())
  ),
  twoWeeks: DateRange(
    "Two Weeks",
    startOfWeek(DateTime.local().minus({ weeks: 1 })),
    endOfWeek(DateTime.local())
  ),
  showInvoiced: false,
  setShowInvoiced: () => {
    return;
  },
  setSchool: () => null,
  setClassId: () => null,
  setEndDate: () => null,
  setStartDate: () => null,
  setInvoiceId: () => null,
  makeDirty: () => null,
  getTimeRange: () => null,
  setTimeRange: () => null,
};

interface WorkItemContextProps {}

export const WorkItemContext =
  React.createContext<IWorkItemContext>(defaultState);

const logger = createLogger("WorkItemContext");

export default function WorkItemContextProvider(
  props: React.PropsWithChildren<WorkItemContextProps>
): JSX.Element {
  const isMounted = useIsMounted();
  const api = useApi();
  const { therapists } = React.useContext(TherapistContext);
  const { classes } = React.useContext(ServicesContext);

  const [state, setState] = React.useState<IWorkItemContext>(defaultState);
  const [refreshTrigger, setRefreshTrigger] = React.useState<number>(0);

  const _setSchool = React.useCallback((school: string) => {
    console.log("setting school to ", school);
    setState((prev) => ({ ...prev, school }));
  }, []);
  const _setEndDate = React.useCallback((end: DateTime | null) => {
    setState((prev) => (end !== null ? { ...prev, end } : prev));
  }, []);
  const _setStartDate = React.useCallback((start: DateTime | null) => {
    setState((prev) => (start !== null ? { ...prev, start } : prev));
  }, []);

  const _setClassId = React.useCallback((classId: string) => {
    setState((prev) => ({ ...prev, classId }));
  }, []);

  const _makeDirty = React.useCallback(
    () => setRefreshTrigger((prev) => prev + 1),
    []
  );

  const _getTimeRange = React.useCallback(
    (key: string) => {
      if (state.timeRanges.has(key)) {
        const range = state.timeRanges.get(key);
        if (!range) return null;
        return range;
      }
      return null;
    },
    [state.timeRanges]
  );

  const _setTimeRange = React.useCallback(
    (key: string) => {
      if (state.timeRanges.has(key)) {
        setState((prev) => ({
          ...prev,
          start: prev.timeRanges.get(key)?.start ?? DateTime.local(),
          end: prev.timeRanges.get(key)?.end ?? DateTime.local(),
        }));
      }
    },
    [state.timeRanges]
  );

  const _setShowInvoiced = React.useCallback(
    (show: boolean) => setState((prev) => ({ ...prev, showInvoiced: show })),
    []
  );

  React.useEffect(() => {
    const today = DateTime.local();
    setState((prev) => ({
      ...prev,
      timeRanges: new Map<string, IDateRange>([
        ["today", DateRange("Today", today.startOf("day"), today.endOf("day"))],
        [
          "yesterday",
          DateRange(
            "Yesterday",
            today.minus({ days: 1 }).startOf("day"),
            today.minus({ days: 1 }).endOf("day")
          ),
        ],
        [
          "thisWeek",
          DateRange("This Week", startOfWeek(today), endOfWeek(today)),
        ],
        [
          "lastWeek",
          DateRange("Last Week", startOfLastWeek(today), endOfLastWeek(today)),
        ],
        [
          "twoWeeks",
          DateRange("Two Weeks", startOfLastWeek(today), endOfWeek(today)),
        ],
        [
          "thisMonth",
          DateRange("This Month", today.startOf("month"), today.endOf("month")),
        ],
        [
          "thisYear",
          DateRange("This Year", today.startOf("year"), today.endOf("year")),
        ],
      ]),
    }));
    const defaultKey = "thisWeek";
    setState((prev) => ({
      ...prev,
      start: prev.timeRanges.get(defaultKey)?.start ?? DateTime.local(),
      end: prev.timeRanges.get(defaultKey)?.end ?? DateTime.local(),
    }));
  }, []);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      setSchool: _setSchool,
      setEndDate: _setEndDate,
      setStartDate: _setStartDate,
      makeDirty: _makeDirty,
      setClassId: _setClassId,
      getTimeRange: _getTimeRange,
      setShowInvoiced: _setShowInvoiced,
      setTimeRange: _setTimeRange,
    }));
  }, [
    _getTimeRange,
    _makeDirty,
    _setClassId,
    _setEndDate,
    _setSchool,
    _setStartDate,
    _setShowInvoiced,
    _setTimeRange,
  ]);

  React.useEffect(() => {
    setState((prev) => ({ ...prev, isLoading: true }));
    api.workItem
      .list()
      .then(async (res) => {
        if (isContentJSON(res)) {
          await res.json().then((j) => {
            if (res.status === 200) {
              if (isMounted.current)
                setState((prev) => ({
                  ...prev,
                  isLoading: false,
                  workItems: j.map(
                    (i: { _id: string; [key: string]: unknown }) => ({
                      ...i,
                      id: i._id,
                    })
                  ),
                }));
            } else if (res.status === 404) {
              logger.error(
                `ERROR Code ${res.status}: ${res.statusText} => ${j.message}`,
                {
                  res,
                  j,
                }
              );
            } else {
              logger.error(
                `ERROR Code ${res.status}: ${res.statusText} => ${j.message}`,
                {
                  res,
                  j,
                }
              );
            }
          });
        }
      })
      .catch((e) => logger.error(e));
  }, [refreshTrigger, _makeDirty, isMounted, api.workItem]);

  React.useEffect(() => {
    const periodWorkItems = filter<IWorkItem>(state.workItems, [
      (item) => {
        const inRange =
          DateTime.fromISO(item.dateWorked).valueOf() >= state.start.valueOf();
        return inRange;
      },
      (item) => {
        const inRange =
          DateTime.fromISO(item.dateWorked).valueOf() <= state.end.valueOf();
        return inRange;
      },
    ]);

    const filteredWorkItems = filter<IWorkItem>(periodWorkItems, [
      (item) => {
        if (state.classId.localeCompare("All") === 0) return true;

        return item.classId === state.classId;
      },
      (item) =>
        state.school === "All" ? true : item.schoolId === state.school,
      (item) =>
        state.workTypeId === "All"
          ? true
          : item.workTypeId === state.workTypeId,
      (item) => (state.showInvoiced ? true : item.invoiceId === null),
    ]);
    setState((prev) => ({ ...prev, filteredWorkItems, periodWorkItems }));
  }, [
    classes,
    state.workItems,
    state.school,
    state.start,
    state.end,
    state.classId,
    therapists,
    state.workTypeId,
    refreshTrigger,
    state.showInvoiced,
  ]);

  return (
    <WorkItemContext.Provider value={state}>
      {props.children}
    </WorkItemContext.Provider>
  );
}
