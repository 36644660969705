import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { CSSProperties, ReactNode } from "react";
import { clsx } from "../../clsx";
import { OrganizerProperties } from "../../common/interfaces";
import { gapSizeToPx } from "../../common/organizer";

interface RowProps extends OrganizerProperties {
  style?: CSSProperties;
  center?: boolean;
}

const useStyles = makeStyles({
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  center: {
    flexGrow: 1,
    width: "100%",
  },
});

export default function Row(
  props: React.PropsWithChildren<RowProps>
): JSX.Element {
  const styles = useStyles();
  const apply_center = (children: ReactNode) => {
    if (props.center) {
      return (
        <div style={{ display: "flex", flexDirection: "row", margin: "auto" }}>
          {children}
        </div>
      );
    }

    return children;
  };
  return (
    <div
      className={clsx({
        [styles.flexRow]: true,
        [styles.center]: props.center,
      })}
      style={Object.assign(
        {},
        props.style,
        props.gap ? { gap: gapSizeToPx(props.gap) } : {}
      )}
    >
      {apply_center(props.children)}
    </div>
  );
}
