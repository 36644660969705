import { JSONSchemaType } from "ajv";
import { ObjectIdMixin, TimestampsMixin } from "../../utils";
import { ajv } from "../..";

export interface InterventionGroup extends TimestampsMixin, ObjectIdMixin {
  title: string;
  classUuid: string;
  interventionIds: string[];
}

export interface CreateInterventionsGroup {
  title: string;
  classUuid: string;
}

export interface DeleteInterventionsGroup {
  id: string;
}

export interface UpdateInterventionsGroup {
  title: string;
}

export const createInterventionsGroupSchema: JSONSchemaType<CreateInterventionsGroup> =
  {
    type: "object",
    properties: {
      title: { type: "string", minLength: 1, maxLength: 50 },
      classUuid: { type: "string", format: "uuid" },
    },
    required: ["title", "classUuid"],
    additionalProperties: false,
  };

export const updateInterventionsGroupSchema: JSONSchemaType<UpdateInterventionsGroup> =
  {
    type: "object",
    properties: {
      title: { type: "string", minLength: 1, maxLength: 50 },
    },
    required: ["title"],
    additionalProperties: false,
  };

export const interventionGroupsValidators = {
  validateCreate: ajv.compile(createInterventionsGroupSchema),
  validateUpdate: ajv.compile(updateInterventionsGroupSchema),
};
