import { makeStyles } from "@material-ui/styles";
import React from "react";
import { clsx } from "../../clsx";
import {
  DropdownSelectorOption,
  ISchool,
  IStudent,
} from "../../common/interfaces";
import { ConfirmDenyButtonAction } from "./ConfirmDenyButtonAction";
import DropdownSelect, { makeDropdownOptions } from "./DropdownSelect";
import { SimpleDatePicker } from "./SimpleDatePicker";
import { UnitSelector } from "./UnitSelector";
import { DateTime } from "luxon";
import { ServicesContext } from "../../context/ServicesContext";
import { Paper, TextField, Typography } from "@material-ui/core";
import Column from "../Display/Column";
import {
  ServiceRecord,
  UpdateServiceRecord,
} from "../../api/schemas/service-records/schema";
import Row from "../Display/Row";
import { useQueries, useQuery } from "react-query";
import { useApi } from "../../api/useApi";
import Select from "react-select";
import { Intervention } from "../../api/schemas/interventions/schema";
import { KeyboardTimePicker } from "@material-ui/pickers";

export interface SingleWorkItemEditorControlsProps {
  editingWorkItem: ServiceRecord;
  schools: ISchool[];
  saveEdit: (params: { id: string; update: UpdateServiceRecord }) => void;
  cancelEdit: (changed: boolean) => void;
}

const useStyles = makeStyles({
  paper: {
    padding: "10px",
    minWidth: "400px",
    marginBottom: "10px",
  },
});

export function SingleWorkItemEditorControls(
  props: SingleWorkItemEditorControlsProps
): JSX.Element {
  const styles = useStyles();
  const { editingWorkItem, schools, saveEdit, cancelEdit } = props;

  const api = useApi();

  const settings = React.useContext(ServicesContext);

  const { data: classInterventions } = useQuery({
    queryKey: api.intervention.group.class.get.queryKey({
      classId: editingWorkItem.classId,
    }),
    queryFn: () =>
      api.intervention.group.class.get({
        classId: editingWorkItem.classId,
      }),
  });

  const [narrativeNote, setNarrativeNote] = React.useState<string>(
    editingWorkItem.narrativeNote
  );

  const [interventionIds, setInterventionIds] = React.useState<string[]>(
    editingWorkItem.interventionIds
  );

  const [workTypeId, setWorkTypeId] = React.useState<string>(
    editingWorkItem.workTypeId
  );

  // Original work item could include deleted interventions so we need to query for those
  const originalInterventionsById = Object.fromEntries(
    useQueries(
      editingWorkItem.interventionIds.map((id) => ({
        queryKey: api.intervention.get.queryKey({ id }),
        queryFn: () => api.intervention.get({ id }),
      }))
    )
      .map((q) => q.data)
      .filter((intervention) => intervention !== undefined)
      .map((intervention) => [intervention._id, intervention])
  );

  const requireStudents = React.useMemo(
    () =>
      settings.classes[editingWorkItem.classId].workTypes[workTypeId]
        .requireStudents,
    [workTypeId, editingWorkItem.classId, settings.classes]
  );

  const workTypeName = React.useMemo(() => {
    if (
      editingWorkItem.classId === "" ||
      workTypeId === "" ||
      Object.keys(settings.classes).length === 0
    )
      return "";

    return (
      settings.classes[editingWorkItem.classId].workTypes[workTypeId].name ?? ""
    );
  }, [editingWorkItem.classId, workTypeId, settings.classes]);

  const [students, setStudents] = React.useState<IStudent[]>(
    editingWorkItem.students
  );

  const [dateAndPossiblyTimeWorked, setDateAndPossiblyTimeWorked] =
    React.useState<DateTime>(DateTime.fromISO(editingWorkItem.dateWorked));

  const [units, setUnits] = React.useState(editingWorkItem.units);

  const workTypeDropDownOptions = React.useMemo(
    () =>
      Object.entries(
        editingWorkItem.classId === ""
          ? []
          : settings.classes[editingWorkItem.classId].workTypes
      ).map(([workTypeId, workType]) => {
        return {
          label: workType["name"],
          value: workTypeId,
        };
      }),
    [editingWorkItem.classId, settings.classes]
  );

  const studentOptions = React.useMemo(() => {
    return (
      schools.find((school) => school.id === editingWorkItem.schoolId)
        ?.students ?? []
    );
  }, [editingWorkItem.schoolId, schools]);

  const remainingStudents = React.useMemo(() => {
    return studentOptions.filter((option) => {
      return !editingWorkItem.students.find(
        (student) => student.name === option.name
      );
    });
  }, [editingWorkItem.students, studentOptions]);

  const classInterventionsById = React.useMemo(
    () =>
      Object.fromEntries(
        (classInterventions ?? [])
          .reduce(
            (prev, group) => [...prev, ...group.interventions],
            [] as Intervention[]
          )
          .map((intervention) => [intervention._id, intervention])
      ),
    [classInterventions]
  );

  // TODO: I think this has right values but it's not showing up in the dropdown. I think this might be because
  // the queries are undefined while they are loading and the dropdown doesn't have state, so when they load in
  // the dropdown doesn't get updated
  const interventionOptions = React.useMemo(() => {
    const groupsAssociatedWithClass = (classInterventions ?? []).map(
      (group) => ({
        label: group.title,
        options: group.interventions
          .filter((intervention) => !interventionIds.includes(intervention._id))
          .map((intervention) => ({
            label: intervention.text,
            value: intervention._id,
          })),
      })
    );

    const archivedGroup = {
      label: "Archived (These interventions have been deleted)",
      options: Object.values(originalInterventionsById)
        .filter((intervention) => intervention.isDeleted)
        .map((intervention) => ({
          label: intervention.text,
          value: intervention._id,
        })),
    };

    return [...groupsAssociatedWithClass, archivedGroup];
  }, [classInterventions, originalInterventionsById, interventionIds]);

  // The __v field was added when we added interventions, start time, and
  // narrative notes, so if a record doesn't have it, we should ignore start
  // start requirements.
  const isLegacyRecord = editingWorkItem.__v === undefined;

  const isDirectService = (() => {
    const workType =
      settings.classes[editingWorkItem.classId].workTypes[workTypeId];
    const workTypeNameHasIndividualOrGroup =
      workType.name.toLowerCase().includes("individual") ||
      workType.name.toLowerCase().includes("group");

    return workTypeNameHasIndividualOrGroup;
  })();

  const showDirectServiceDataFields =
    isDirectService && !isLegacyRecord && requireStudents;

  if (editingWorkItem === undefined) return <>Item was null</>;

  return (
    <Paper className={clsx(styles.paper)}>
      <Column gap="m">
        <Typography component="h3" variant="h6" color="inherit" noWrap>
          Edit Work Item
        </Typography>
        <SimpleDatePicker
          label={"Date Worked"}
          value={dateAndPossiblyTimeWorked}
          onChange={(date) => {
            if (!date) return;
            try {
              setDateAndPossiblyTimeWorked(
                dateAndPossiblyTimeWorked.set({
                  day: date.day,
                  month: date.month,
                  year: date.year,
                })
              );
            } catch {
              return; // disable keyboard
            }
          }}
          style={{ width: "100%" }}
        />
        <DropdownSelect
          label={"Service"}
          value={{
            label: editingWorkItem.className,
            value: editingWorkItem.classId,
          }}
          options={[]}
          handleChange={() => {
            return;
          }}
          isDisabled
        />

        <DropdownSelect
          label={"School"}
          value={{
            label:
              schools.find((s) => s.id === editingWorkItem.schoolId)?.name ??
              "",
            value: editingWorkItem.schoolId,
          }}
          handleChange={() => undefined}
          options={[]}
          required
          isDisabled
        />

        <DropdownSelect
          isMulti
          label={"Student(s)"}
          options={makeDropdownOptions(remainingStudents, "name", "name")}
          value={makeDropdownOptions(students, "name", "name")}
          handleChange={(result) => {
            if (!result) return;
            const selections = result as DropdownSelectorOption[];

            const names = selections.map((s) => s.value);

            setStudents(
              remainingStudents.filter((student) =>
                names.includes(student.name)
              )
            );
          }}
          isClearable
        />

        <Row gap="m">
          <DropdownSelect
            value={{
              label: workTypeName,
              value: workTypeId,
            }}
            options={workTypeDropDownOptions}
            label={"Work Type"}
            handleChange={(change) => {
              const option = change as DropdownSelectorOption;
              if (option.value) setWorkTypeId(option.value);
            }}
            required
          />
          <UnitSelector label={"Units"} value={units} onChange={setUnits} />
        </Row>

        {showDirectServiceDataFields && (
          <>
            <KeyboardTimePicker
              label={"Start time"}
              id="time-picker"
              inputVariant="outlined"
              value={dateAndPossiblyTimeWorked}
              onChange={(time) => {
                if (!time) return;
                try {
                  setDateAndPossiblyTimeWorked(
                    dateAndPossiblyTimeWorked.set({
                      hour: time.hour,
                      minute: time.minute,
                      second: time.second,
                      millisecond: time.millisecond,
                    })
                  );
                } catch {
                  return; // disable keyboard input
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />

            <Select
              isMulti
              placeholder="Interventions"
              value={interventionIds.map((id) => ({
                label:
                  classInterventionsById[id]?.text ||
                  originalInterventionsById[id]?.text ||
                  "...",
                value: id,
              }))}
              menuPortalTarget={document.body}
              onChange={(interventions) => {
                setInterventionIds(
                  interventions.map(
                    (intervention) =>
                      // For some reason typescript doesn't think this is the type that it actually is.
                      (
                        intervention as unknown as {
                          label: string;
                          value: string;
                        }
                      ).value
                  )
                );
              }}
              options={interventionOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  minHeight: "54px",
                }),
                menuPortal: (provided: { [key: string]: unknown }) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
            />

            <TextField
              variant="outlined"
              label="Narrative Note"
              style={{
                width: "100%",
                boxShadow: "none",
                minHeight: "54px",
              }}
              multiline
              value={narrativeNote}
              onChange={(change) => setNarrativeNote(change.target.value)}
            />
          </>
        )}
        <ConfirmDenyButtonAction
          confirmText="Save"
          denyText="Cancel"
          confirmAction={() => {
            saveEdit({
              id: editingWorkItem._id,
              update: {
                dateWorked: dateAndPossiblyTimeWorked.toISO(),
                workTypeId,
                units,
                students,
                narrativeNote: showDirectServiceDataFields ? narrativeNote : "",
                interventionIds: showDirectServiceDataFields
                  ? interventionIds
                  : [],
                hasStartTime: showDirectServiceDataFields,
              },
            });
          }}
          denyAction={() => cancelEdit(false)}
        />
      </Column>
    </Paper>
  );
}
