/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, makeStyles, TextField, withStyles } from "@material-ui/core";
import React, { ChangeEvent, useRef } from "react";
import DropdownSelect from "./DropdownSelect";
import {
  DropdownSelectorOption,
  IPartialWorkItem,
  IWorkType,
} from "../../common/interfaces";
import { isObjectEmpty } from "../../common/Utils";
import HeaderWithRightSection from "../Display/HeaderWithRightSection";
import Row from "../Display/Row";
import Column from "../Display/Column";

interface ITypeUnitPairSelectorProps {
  workTypes: { [uuid: string]: IWorkType };
  additionalItems: IPartialWorkItem[];
  classId: string;
  addAdditionalItem: (item: IPartialWorkItem) => void;
  removeAdditionalItem: (id: number) => void;
}

export function TypeUnitPairSelector(
  props: ITypeUnitPairSelectorProps
): JSX.Element {
  const { workTypes, classId } = props;
  const [workTypeId, setWorkTypeId] = React.useState<string | undefined>(
    undefined
  );
  const [workTypeName, setWorkTypeName] = React.useState<string | undefined>(
    undefined
  );
  const [units, setUnits] = React.useState<number>(0);
  const [itemsCreated, setItemsCreated] = React.useState<number>(0);
  const incrementItemsCreated = () => setItemsCreated((prev) => prev + 1);
  const errorTypeSelect = React.useRef<boolean>(false);
  const errorUnits = React.useRef<boolean>(false);

  const handleAdd = () => {
    const id = itemsCreated;
    if (workTypeId === undefined || workTypeName === undefined) {
      errorTypeSelect.current = true;
      return;
    }
    if (units === 0) {
      errorUnits.current = true;
      return;
    }

    const item: IPartialWorkItem = {
      classId,
      workTypeId,
      workTypeName,
      unit: units,
      id: id,
    };
    props.addAdditionalItem(item);
    incrementItemsCreated();
    setWorkTypeId(undefined);
    setWorkTypeName(undefined);
    setUnits(0);
  };

  const workTypeOptions = React.useMemo(
    () =>
      Object.entries(workTypes)
        .sort((a, b) => a[1].name.localeCompare(b[1].name))
        .map(([id, workType]) => ({
          label: workType.name,
          value: id,
        })),
    [workTypes]
  );

  const handleWorkTypeChange = React.useCallback(
    (e: DropdownSelectorOption) => {
      errorTypeSelect.current = false;
      setWorkTypeId(e.value);
      setWorkTypeName(e.label);
    },
    []
  );

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <HeaderWithRightSection
      style={{ padding: "10px" }}
      leftSection={
        <Row gap="m">
          <DropdownSelect
            value={{
              label:
                workTypeId === undefined || isObjectEmpty(workTypes)
                  ? ""
                  : workTypes[workTypeId].name,
              value: workTypeId ?? "",
            }}
            handleChange={(ns) => {
              if (ns) {
                handleWorkTypeChange(ns as DropdownSelectorOption);
              }
            }}
            options={workTypeOptions}
            clearOnSelect={true}
          />
          <TextField
            label="Units"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: {} }}
            value={units}
            variant="outlined"
            inputRef={inputRef}
            onFocus={() => inputRef.current?.select()}
            required
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              if (event?.target?.value) {
                errorUnits.current = false;
                setUnits(parseInt(event.target.value));
              }
            }}
            error={errorUnits.current}
          />
        </Row>
      }
      rightSection={
        <Column center style={{ paddingLeft: "10px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleAdd}
            style={{ height: "56px" }}
          >
            <b>Add</b>
          </Button>
        </Column>
      }
    />
  );
}
