import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { IDateRange } from "../common/interfaces";
import {
  endOfLastWeek,
  endOfWeek,
  startOfLastWeek,
  startOfWeek,
} from "../common/Utils";

export function useDateRanges() {
  const [now, setNow] = useState(DateTime.local());

  useEffect(() => {
    // Any hooks which rely on date ranges will be re-rendered along with this hook. This includes
    // The service records database query, so I'm picking a conservative update interval of 5 minutes
    const refreshTrigger = setInterval(() => setNow(DateTime.local()), 300000);
    return () => clearInterval(refreshTrigger);
  });

  const dateRanges = {
    today: {
      title: "Today",
      start: now.startOf("day"),
      end: now.endOf("day"),
    } as IDateRange,
    yesterday: {
      title: "Yesterday",
      start: now.minus({ days: 1 }).startOf("day"),
      end: now.minus({ days: 1 }).endOf("day"),
    } as IDateRange,
    thisWeek: {
      title: "This Week",
      start: startOfWeek(now),
      end: endOfWeek(now),
    } as IDateRange,
    lastWeek: {
      title: "Last Week",
      start: startOfLastWeek(now),
      end: endOfLastWeek(now),
    } as IDateRange,
    twoWeeks: {
      title: "Last Two Weeks",
      start: startOfLastWeek(now),
      end: endOfWeek(now),
    } as IDateRange,
    thisMonth: {
      title: "This Month",
      start: now.startOf("month"),
      end: now.endOf("month"),
    } as IDateRange,
    thisYear: {
      title: "This Year",
      start: now.startOf("year"),
      end: now.endOf("year"),
    } as IDateRange,
  };

  return { now, dateRanges };
}
