import React, { createContext, useEffect, useState } from "react";
import { useApi } from "../api/useApi";
import { createLogger } from "../components/Logging/Logging";
import { InterventionGroup } from "../api/schemas/interventions/groups/schema";
import { useLoad } from "../hooks/useLoad";

interface InterventionGroupsContextProps {}
type InterventionGroupsContextType = {
  interventionGroups: InterventionGroup[];
  reload: () => void;
};

const logger = createLogger("InterventionGroupsContext");

export const InterventionGroupsContext =
  createContext<InterventionGroupsContextType>({
    interventionGroups: [],
    reload: () => undefined,
  });

export default function InterventionGroupsContextProvider(
  props: React.PropsWithChildren<InterventionGroupsContextProps>
): JSX.Element {
  const api = useApi();

  const [state, setState] = useState<InterventionGroupsContextType>({
    reload: () => undefined,
    interventionGroups: [],
  });

  const { load } = useLoad();

  useEffect(() => {
    load("interventionGroups", api.intervention.group.list(), setState);
    setState((prev) => ({
      ...prev,
      reload: async () => {
        const status = await load(
          "interventionGroups",
          api.intervention.group.list(),
          setState
        );
        if (status) {
          logger.info("InterventionGroupsContext loaded");
        } else {
          logger.info("Failed to load InterventionGroupsContext");
        }
      },
    }));
  }, [api, load]);

  return (
    <InterventionGroupsContext.Provider value={state}>
      {props.children}
    </InterventionGroupsContext.Provider>
  );
}
