/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IconButton, TableCell, TableCellProps } from "@material-ui/core";
import React, { ReactElement, ReactNode } from "react";
import { IconType } from "../icons";
import { hasProp } from "./Utils";

export type MemberAction<T> = (input: WithIndex<T>) => void;
export type AlternateFactory<T> = (input: WithIndex<T>) => ReactElement;
export type AlternateElementMap<T> = Record<string, AlternateFactory<T>>;
export type Action<T> = MemberAction<T>;
export type Actions<T> = Action<T>[];
export type OverrideScript<T> = OverrideVerse<T>[];

export type WithIndex<T> = T & {
  index: number;
};
export interface AlternateID {
  alternate: string;
  index: number;
}
export interface OverrideVerse<T> extends Partial<TableCellProps> {
  id: string;
  text: string;
  action?: Action<T> | Actions<T>;
  actionIcon?: IconType | IconType[];
  isAction?: boolean;
  alternates?: AlternateElementMap<T>;
  setDisabled?: (member: T) => boolean;
  getField?: (member: T) => ReactNode;
  comparator?: (a: T, b: T, orderBy: keyof T) => number;
}

function makeSingleAction<T>(
  action: Action<T>,
  icon: IconType,
  disabled: boolean,
  member: WithIndex<T>
) {
  if (!action) return;
  return (
    <IconButton size="small" onClick={() => action(member)} disabled={disabled}>
      {icon}
    </IconButton>
  );
}

function makeMultiAction<T>(
  actions: Actions<T>,
  icons: IconType[],
  disabled: boolean,
  member: WithIndex<T>
) {
  if (!actions) return;
  if (actions.length !== icons.length) return;
  return actions.map((action, index) => {
    const icon = icons[index];
    if (!action || !icon) return;
    return (
      <IconButton
        key={`action-${member.index}.${index}`}
        onClick={() => action(member)}
        disabled={disabled}
      >
        {icon}
      </IconButton>
    );
  });
}

function makeActionCell<T>(
  override: OverrideVerse<T>,
  member: WithIndex<T>,
  currentAlternate?: AlternateID
) {
  let cellContent;
  const {
    alternates,
    action,
    actionIcon,
    isAction,
    getField,
    setDisabled,
    ...rest
  } = override;
  void isAction, getField;
  if (
    currentAlternate &&
    alternates &&
    hasProp(alternates, currentAlternate.alternate) &&
    member.index === currentAlternate.index
  ) {
    cellContent = alternates[currentAlternate.alternate](member);
  } else if (!action || !actionIcon) {
    cellContent = <span>Missing</span>;
  } else if (Array.isArray(action) && Array.isArray(actionIcon)) {
    cellContent = makeMultiAction(
      action as Actions<T>,
      actionIcon as IconType[],
      setDisabled?.(member) ?? false,
      member
    );
  } else {
    cellContent = makeSingleAction(
      action as Action<T>,
      actionIcon as unknown as IconType,
      setDisabled?.(member) ?? false,
      member
    );
  }
  return (
    <TableCell key={`action-${member.index}`} {...rest}>
      {cellContent}
    </TableCell>
  );
}

function makeDataCell<T>(
  override: OverrideVerse<T>,
  member: WithIndex<T>,
  currentAlternate?: AlternateID
) {
  const { alternates, getField } = override;
  const rest = getHTMLProps(override);
  if (!getField) return;
  let content;
  if (
    currentAlternate &&
    alternates &&
    hasProp(alternates, currentAlternate.alternate) &&
    member.index === currentAlternate.index
  ) {
    content = alternates[currentAlternate.alternate](member);
  } else {
    content = getField(member);
  }
  return (
    <TableCell key={`cell-${member.index}-${override.text}`} {...rest}>
      {content}
    </TableCell>
  );
}

function makeCell<T>(
  override: OverrideVerse<T>,
  member: WithIndex<T>,
  currentAlternate?: AlternateID
) {
  if (override.isAction) {
    return makeActionCell(override, member, currentAlternate);
  } else {
    return makeDataCell(override, member, currentAlternate);
  }
}

function wrapIndex<T>(member: T, index: number) {
  return { ...member, index } as WithIndex<T>;
}

function getHTMLProps<T>(verse: OverrideVerse<T>) {
  const {
    id,
    text,
    action,
    actionIcon,
    isAction,
    alternates,
    getField,
    setDisabled,
    comparator,
    ...rest
  } = verse;
  void id,
    text,
    action,
    actionIcon,
    isAction,
    alternates,
    getField,
    setDisabled,
    comparator;
  return rest;
}

export const ScriptFactory = {
  makeCell,
  wrapIndex,
  getHTMLProps,
};
