import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { Fragment } from "react";

interface BackButtonProps {
  onClick: () => void;
}

export default function BackButton(props: BackButtonProps): JSX.Element {
  return (
    <Fragment>
      <IconButton style={{width: "fit-content", height: "fit-content"}} onClick={props.onClick}>
        <ArrowBack />
      </IconButton>
    </Fragment>
  );
}