import { OrganizerProperties } from "./interfaces";

export function gapSizeToPx(gap: OrganizerProperties["gap"]): "0" | "5px" | "10px" | "20px" {
  switch (gap) {
    case "none": return "0";
    case "s": return "5px";
    case "m": return "10px";
    case "l": return "20px";
    default: return "0";
  }
}