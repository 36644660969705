import React from "react";
import { IInvoiceContext } from "../common/interfaces";
import { useIsMounted } from "../common/useIsMounted";
import { isContentHTML, isContentJSON, isContentText } from "../common/Utils";
import { createLogger } from "../components/Logging/Logging";
import { useApi } from "../api/useApi";

interface IInvoiceContextProps {}

const defaultState: IInvoiceContext = {
  invoices: [],
  isLoading: true,
  filteredInvoices: [],
  school: "All",
  classification: "All",
  makeInvoicesDirty: () => null,
  onDownload: () => null,
  setSchool: () => null,
  setClassification: () => null,
};

type InvoiceObject = {
  _id: string;
  json: {
    metadata: {
      [key: string]: string;
    };
  };
};

export const InvoiceContext =
  React.createContext<IInvoiceContext>(defaultState);

const logger = createLogger("InvoiceContext");

export default function InvoiceContextProvider(
  props: React.PropsWithChildren<IInvoiceContextProps>
): JSX.Element {
  const isMounted = useIsMounted();
  const api = useApi();
  const [refreshTrigger, setRefreshTrigger] = React.useState<number>(0);

  const [invoiceContext, setInvoices] = React.useState<IInvoiceContext>({
    ...defaultState,
    onDownload: (id) => {
      logger.info("download: " + id);
    },
  });

  const _setSchool = React.useCallback(
    (school: string) => setInvoices((prev) => ({ ...prev, school })),
    []
  );
  const _setClassification = React.useCallback((classification: string) => {
    setInvoices((prev) => ({ ...prev, classification }));
  }, []);

  const _makeDirty = React.useCallback(
    () => setRefreshTrigger((prev) => prev + 1),
    []
  );

  React.useEffect(() => {
    setInvoices((prev) => ({
      ...prev,
      setSchool: _setSchool,
      setClassification: _setClassification,
      makeInvoicesDirty: _makeDirty,
    }));
  }, [_makeDirty, _setClassification, _setSchool]);

  React.useEffect(() => {
    api.invoice
      .list()
      .then(async (res) => {
        if (isContentJSON(res)) {
          await res
            .json()
            .then((invoiceList) => {
              if (res.status === 200) {
                if (isMounted.current) {
                  setInvoices((prev) => ({
                    ...prev,
                    invoices: invoiceList.map((i: InvoiceObject) => {
                      const metadata = i.json.metadata;
                      return {
                        classification: metadata.classification,
                        invoiceDate: metadata.invoiceDate,
                        schoolId: metadata.schoolId,
                        invoiceNumber: metadata.invoiceIdentifier,
                        _id: i._id,
                      };
                    }),
                  }));
                }
              } else {
                logger.error(
                  `ERROR Code ${res.status}: ${res.statusText} => ${invoiceList.message}`,
                  res
                );
              }
            })
            .catch((e) => logger.error(e));
        } else if (isContentHTML(res)) {
        } else if (isContentText(res)) {
          res.text().then((t) => logger.error(t));
        }
      })
      .catch((e) => logger.error(e));
    setInvoices((prev) => ({ ...prev, isLoading: false }));
  }, [isMounted, refreshTrigger, api.invoice]);

  React.useEffect(() => {
    const filteredInvoices = invoiceContext.invoices
      .filter((inv) =>
        invoiceContext.school === "All"
          ? true
          : inv.schoolId === invoiceContext.school
      )
      .filter((inv) =>
        invoiceContext.classification === "All"
          ? true
          : inv.classification === invoiceContext.classification
      );
    setInvoices((prev) => ({ ...prev, filteredInvoices }));
  }, [
    invoiceContext.invoices,
    invoiceContext.school,
    invoiceContext.classification,
    refreshTrigger,
  ]);

  React.useEffect(() => {
    logger.info(`loaded = ${!invoiceContext.isLoading}`);
  }, [invoiceContext.isLoading]);

  return (
    <InvoiceContext.Provider value={invoiceContext}>
      {props.children}
    </InvoiceContext.Provider>
  );
}
