import { Button, TextField } from "@material-ui/core";
import React, { Fragment, useContext, useState } from "react";
import useModal from "../hooks/useModal";
import SectionHeaderText from "./Display/SectionHeaderText";
import { DateTime } from "luxon";
import { WorkItemContext } from "../context/WorkItemContext";
import { useStudentServiceDetails } from "../hooks/useStudentServiceDetails";
import { RangeDatePicker } from "./RangeDatePicker/RangeDatePicker";
import Column from "./Display/Column";

export function StudentServiceDetailsSearchControls() {
  const { modal: Modal, toggle } = useModal();
  const [name, setName] = useState("");
  const { thisWeek, timeRanges } = useContext(WorkItemContext);

  const [start, setStart] = useState<DateTime>(thisWeek.start);
  const [end, setEnd] = useState<DateTime>(thisWeek.end);
  const { downloadCSV } = useStudentServiceDetails();

  const onClose = () => {
    setName("");
    setStart(thisWeek.start);
    setEnd(thisWeek.end);
  };

  return (
    <Fragment>
      <Button variant="outlined" color="primary" fullWidth onClick={toggle}>
        Student Service Details
      </Button>
      <Modal
        onClose={() => {
          toggle();
          onClose();
        }}
      >
        <Column style={{ padding: "10px" }} gap="m">
          <SectionHeaderText>
            Download Student Service Details
          </SectionHeaderText>
          <TextField
            variant="outlined"
            label="Name"
            onChange={(event) => setName(event.target.value)}
          />
          <RangeDatePicker
            style={{ width: "100%" }}
            start={start}
            end={end}
            setStart={(start: DateTime | null) => {
              if (start) {
                setStart(start);
              }
            }}
            setEnd={(end: DateTime | null) => {
              if (end) {
                setEnd(end);
              }
            }}
            predefinedOptions={Array.from(timeRanges.values())}
          />
          <Button
            color="primary"
            variant="outlined"
            onClick={async () => {
              if (!name) return;
              const ok = await downloadCSV({ name, start, end });
              if (ok) {
                toggle();
                onClose();
              }
            }}
          >
            Download
          </Button>
        </Column>
      </Modal>
    </Fragment>
  );
}
