import { Paper, makeStyles } from "@material-ui/core";
import React from "react";
import { SchoolContext } from "../../context/SchoolContext";
import { DeleteIcon } from "../../icons";
import { SimpleActionTable } from "../Display/Table/SimpleActionTable";
import DropdownSelect from "../Input/DropdownSelect";
import { DropdownSelectorOption } from "../../common/interfaces";

export interface SchoolSelectionTableProps {
  selected: string[];
  addSchool: (grade: string) => void;
  removeSchool: (name: string) => void;
  maxHeight?: number | string;
}

const useStyles = makeStyles({
  input: {
    width: "95%",
    margin: "10px 10px",
    minWidth: "10%",
  },
});

export function SchoolSelectionTable(
  props: SchoolSelectionTableProps
): JSX.Element {
  const { schools } = React.useContext(SchoolContext);
  const { selected, addSchool, removeSchool } = props;
  const styles = useStyles();

  const add = React.useCallback(
    (selection: string) => {
      addSchool(selection);
    },
    [addSchool]
  );

  const notSelected = React.useMemo(
    () => schools.filter((school) => !selected.includes(school.id)),
    [schools, selected]
  );
  const prettySelected = React.useMemo(
    () =>
      schools
        .filter((school) => selected.includes(school.id))
        .map((school) => `${school.name} ${school.invoicePrefix}`),
    [schools, selected]
  );

  return (
    <Paper elevation={3}>
      <SimpleActionTable
        population={prettySelected}
        title={"School"}
        action={removeSchool}
        actionIcon={<DeleteIcon />}
        tableContainerProps={{
          maxHeight: props.maxHeight ?? "",
          overflowY: "auto",
        }}
      />
      <DropdownSelect
        className={styles.input}
        label="School"
        value={{ value: "", label: notSelected.length + " Options" }}
        handleChange={(selection) => {
          if (selection) add((selection as DropdownSelectorOption).value);
        }}
        options={notSelected.map((school) => ({
          value: school.id,
          label: `${school.name} ${school.invoicePrefix}`,
        }))}
        disabled={notSelected.length === 0}
        clearOnSelect
      />
    </Paper>
  );
}
