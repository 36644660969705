import { JSONSchemaType } from "ajv";
import { ObjectIdMixin, objectIdSchema, TimestampsMixin } from "../utils";
import { ajv } from "..";

export interface Intervention extends TimestampsMixin, ObjectIdMixin {
  text: string;
  className: string;
  isDeleted: boolean;
}

export interface DeleteIntervention {
  id: string;
}

export interface CreateIntervention {
  text: string;
  groupId: string;
}

export const createInterventionSchema: JSONSchemaType<CreateIntervention> = {
  type: "object",
  properties: {
    text: { type: "string", maxLength: 200 },
    groupId: objectIdSchema,
  },
  required: ["text", "groupId"],
  additionalProperties: false,
};

export const interventionValidators = {
  validateCreate: ajv.compile(createInterventionSchema),
};
