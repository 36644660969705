import { Button, IconButton, Typography } from "@material-ui/core";
import React from "react";
import { IDateRange } from "../../common/interfaces";
import RangeDatePickerDialog from "./RangeDatePickerDialog";
import EventIcon from "@material-ui/icons/Event";
import { makeStyles } from "@material-ui/styles";
import { DateTime } from "luxon";
import { toInterval } from "../../common/Utils";
import Row from "../Display/Row";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
export interface RangeDatePickerProps {
  start: DateTime;
  end: DateTime;
  predefinedOptions?: IDateRange[];
  defaultOption?: IDateRange;
  setStart: (start: DateTime) => void;
  setEnd: (end: DateTime) => void;
  style?: React.CSSProperties;
}

const useStyles = makeStyles({
  pickerButton: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRight: "0px",
    borderRadius: "4px 0px 0px 4px",
    display: "flex",
    textTransform: "none",
  },
  pickerIcon: {
    margin: "6px",
    color: "rgba(0, 0, 0, 0.54)",
    width: "1.1em",
    height: "1.1em",
  },
  pickerContainer: {
    display: "flex",
    alignContent: "center",
  },
  pickerLeftArrow: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "0px",
    paddingRight: "-5px",
    width: "50px",
  },
  pickerRightArrow: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderLeft: "0px",
    borderRadius: "0px 4px 4px 0px",
    width: "50px",
  },
  noBreak: {
    whiteSpace: "nowrap",
  },
});

export function RangeDatePicker(props: RangeDatePickerProps): JSX.Element {
  const { start, end, setStart, setEnd } = props;
  const [pickerDialogAnchor, setPickerDialogAnchor] =
    React.useState<HTMLElement | null>(null);
  const styles = useStyles();
  const handleOpenDatePicker = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setPickerDialogAnchor(event.currentTarget);
    },
    []
  );

  const handleWeekForwardClick = React.useCallback(() => {
    if (toInterval(end, start).toDuration("weeks").toObject().weeks !== 1) {
      setEnd(start.plus({ weeks: 1 }));
    }
    setStart(start.plus({ weeks: 1 }));
    setEnd(end.plus({ weeks: 1 }));
  }, [end, start, setStart, setEnd]);

  const handleWeekBackwardClick = React.useCallback(() => {
    if (toInterval(end, start).toDuration("weeks").toObject().weeks !== 1) {
      setEnd(start.minus({ weeks: 1 }));
    }
    setStart(start.minus({ weeks: 1 }));
    setEnd(end.minus({ weeks: 1 }));
  }, [end, start, setStart, setEnd]);

  return (
    <Row style={props.style}>
      <Button
        onClick={handleOpenDatePicker}
        size={"large"}
        aria-haspopup="true"
        startIcon={<EventIcon className={styles.pickerIcon} />}
        className={styles.pickerButton}
        fullWidth
      >
        <Typography>
          <span className={styles.noBreak}>{`${start.toFormat(
            "LLL dd"
          )} `}</span>
          {"- "}
          <span className={styles.noBreak}>{`${end.toFormat("LLL dd")}`}</span>
        </Typography>
      </Button>
      <IconButton
        className={styles.pickerLeftArrow}
        onClick={handleWeekBackwardClick}
      >
        <KeyboardArrowLeft fontSize="large" />
      </IconButton>
      <IconButton
        className={styles.pickerRightArrow}
        onClick={handleWeekForwardClick}
      >
        <KeyboardArrowRight fontSize="large" />
      </IconButton>
      <RangeDatePickerDialog
        {...props}
        menuAnchor={pickerDialogAnchor}
        setMenuAnchor={setPickerDialogAnchor}
      />
    </Row>
  );
}
