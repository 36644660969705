import { PropsWithChildren, useMemo, useState } from "react";
import { DefaultModal } from "../components/Display/DefaultModal";
import React from "react";
import { Paper, PaperProps } from "@material-ui/core";

interface ModalProps {
  paperProps?: PaperProps;
  onClose?: () => boolean | undefined | void;
}

export default function useModal() {
  const [open, setOpen] = useState<boolean>(false);

  return useMemo(
    () => ({
      modal: (props: PropsWithChildren<ModalProps>): JSX.Element =>
        (
          <DefaultModal
            open={open}
            onClose={() => {
              const canClose = props.onClose?.() ?? true;
              if (canClose) {
                setOpen(false);
              }
            }}
          >
            <Paper {...(props.paperProps ?? {})}>{props.children}</Paper>
          </DefaultModal>
        ) as JSX.Element,
      toggle: () => setOpen(!open),
    }),
    [open]
  );
}
