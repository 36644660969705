import { FormControlLabel, Switch } from "@material-ui/core";
import React from "react";
import Row from "../Display/Row";

interface ShowInvoicedToggleProps {
  toggle: () => void;
  showInvoiced: boolean;
}

export default function ShowInvoicedSwitch(
  props: ShowInvoicedToggleProps
): JSX.Element {
  return (
    <Row>
      <FormControlLabel
        control={
          <Switch
            checked={props.showInvoiced}
            onChange={props.toggle}
            color="primary"
          />
        }
        label="Show Invoiced"
      />
    </Row>
  );
}
