import { Paper, TextField } from "@material-ui/core";
import { DateTime } from "luxon";
import React from "react";
import { useApi } from "../../api/useApi";
import { formatISO } from "../../common/DateUtils";
import {
  DropdownSelectorOption,
  IPartialWorkItem,
  ISchool,
  IStudent,
  ITherapist,
} from "../../common/interfaces";
import Toaster, { ToastType } from "../../common/Toaster";
import { isObjectEmpty } from "../../common/Utils";
import { SchoolContext } from "../../context/SchoolContext";
import { ServicesContext } from "../../context/ServicesContext";
import SectionHeaderText from "../Display/SectionHeaderText";
import Select from "react-select";
import { ConfirmDenyButtonAction } from "./ConfirmDenyButtonAction";
import DropdownSelect, { makeDropdownOptions } from "./DropdownSelect";
import { SimpleDatePicker } from "./SimpleDatePicker";
import { WorkItemSelector } from "./WorkItemSelector";
import { useQuery, useQueryClient } from "react-query";
import { KeyboardTimePicker } from "@material-ui/pickers";

interface IWorkItemFormProps {
  therapist: ITherapist;
  setOpen?: (val: boolean) => void;
}

export interface IValidationState {
  type: boolean;
  school: boolean;
  units: boolean;
  students: boolean;
}

export default function WorkItemForm(props: IWorkItemFormProps): JSX.Element {
  const { therapist, setOpen } = props;
  const schoolContext = React.useContext(SchoolContext);
  const { classes } = React.useContext(ServicesContext);
  const api = useApi();
  const queryClient = useQueryClient();

  const [selectedSchool, setSelectedSchool] = React.useState<
    ISchool | undefined
  >(undefined);
  const [selectedStudents, setSelectedStudents] = React.useState<IStudent[]>(
    []
  );
  const [dateAndPossiblyTimeWorked, setDateAndPossiblyTimeWorked] =
    React.useState<DateTime>(DateTime.local());

  const [selectedPartialWorkItems, setSelectedWorkTypes] = React.useState<
    IPartialWorkItem[]
  >([]);

  const [selectedInterventionIds, setSelectedInterventionIds] = React.useState<
    string[]
  >([]);

  const [narrativeNote, setNarrativeNote] = React.useState<string>("");

  const schools = React.useMemo(() => {
    if (therapist) {
      return schoolContext.schools.filter((s) =>
        therapist.schoolIds.includes(s.id)
      );
    } else {
      return [];
    }
  }, [therapist, schoolContext]);

  const sortedStudents = React.useMemo(() => {
    return (selectedSchool?.students ?? []).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }, [selectedSchool]);

  const therapistWorkTypes = React.useMemo(() => {
    if (therapist && !isObjectEmpty(classes)) {
      return classes[therapist.classId].workTypes;
    } else {
      return {};
    }
  }, [therapist, classes]);

  const { data: therapistInterventions } = useQuery({
    queryKey: api.intervention.group.therapist.get.queryKey({
      id: props.therapist.userId,
    }),
    queryFn: () =>
      api.intervention.group.therapist.get({
        id: props.therapist.userId,
      }),
    initialData: [],
  });

  const clearSelected = React.useCallback(() => {
    setSelectedSchool(undefined);
    setSelectedInterventionIds([]);
    setSelectedStudents([]);
    setSelectedWorkTypes([]);
  }, []);

  const includesDirectServiceData = React.useCallback(
    (workItem: IPartialWorkItem) => {
      const requiresStudents =
        classes[workItem.classId].workTypes[workItem.workTypeId]
          .requireStudents;

      const isDirectService =
        workItem.workTypeName.toLowerCase().includes("individual") ||
        workItem.workTypeName.toLowerCase().includes("group");

      return requiresStudents && isDirectService;
    },
    [classes]
  );

  const handleSubmission = React.useCallback(async () => {
    if (selectedPartialWorkItems.length === 0) {
      Toaster("A work type is required", ToastType.error);
      return;
    }
    if (!selectedSchool) {
      Toaster("A School district is required", ToastType.error);
      return;
    }

    await Promise.all(
      selectedPartialWorkItems.map(async (partialWorkItem) => {
        const doIncludeDirectServiceData =
          includesDirectServiceData(partialWorkItem);
        return await api.serviceRecord
          .add({
            schoolId: selectedSchool.id,
            dateWorked: formatISO(dateAndPossiblyTimeWorked),
            workTypeId: partialWorkItem.workTypeId,
            classId: partialWorkItem.classId,
            units: partialWorkItem.unit,
            students: selectedStudents,
            therapistUserId: therapist.userId,
            narrativeNote: doIncludeDirectServiceData ? narrativeNote : "",
            interventionIds: doIncludeDirectServiceData
              ? selectedInterventionIds
              : [],
            hasStartTime: doIncludeDirectServiceData,
          })
          .then((result) => {
            const workTypeName =
              classes[partialWorkItem.classId].workTypes[
                partialWorkItem.workTypeId
              ].name;
            Toaster(`${workTypeName} submitted successfully`, ToastType.good);
            return result;
          });
      })
    )
      .then(() => {
        setOpen?.(false);
        clearSelected();
        queryClient.invalidateQueries(api.serviceRecord.list.queryKey({}));
      })
      .catch((error) => {
        Toaster(error.message, ToastType.error);
      });
  }, [
    selectedPartialWorkItems,
    selectedSchool,
    api.serviceRecord,
    dateAndPossiblyTimeWorked,
    selectedStudents,
    therapist.userId,
    narrativeNote,
    selectedInterventionIds,
    classes,
    setOpen,
    clearSelected,
    queryClient,
    includesDirectServiceData,
  ]);

  const addAdditionalItem = React.useCallback((item: IPartialWorkItem) => {
    setSelectedWorkTypes((prev) => [...prev, item]);
  }, []);

  const removeAdditionalItem = React.useCallback(
    (id: number) => {
      setSelectedWorkTypes(
        selectedPartialWorkItems.filter((ai) => ai.id !== id)
      );
    },
    [selectedPartialWorkItems]
  );

  const showDirectServiceFields = selectedPartialWorkItems.some(
    includesDirectServiceData
  );

  return (
    <Paper
      style={{
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        gap: "15px",
        padding: "10px",
      }}
      elevation={3}
    >
      <SectionHeaderText>Submit Student Service Record</SectionHeaderText>
      <SimpleDatePicker
        label={"Date Worked"}
        value={dateAndPossiblyTimeWorked}
        onChange={(date) => {
          if (!date) return;
          try {
            setDateAndPossiblyTimeWorked(
              dateAndPossiblyTimeWorked.set({
                day: date.day,
                month: date.month,
                year: date.year,
              })
            );
          } catch {
            return; // disable keyboard
          }
        }}
        style={{ width: "100%" }}
      />
      <DropdownSelect
        label={"District"}
        value={{
          label: selectedSchool?.name ?? "",
          value: selectedSchool?.id ?? "",
        }}
        handleChange={(result) =>
          setSelectedSchool(
            schools.find(
              (school) =>
                school.id === (result as DropdownSelectorOption)?.value
            )
          )
        }
        options={makeDropdownOptions(schools, "name", "id")}
        required
        error={false}
        isClearable
        forcePlaceholder
      />

      <DropdownSelect
        isMulti
        label={"Student(s)"}
        options={makeDropdownOptions(sortedStudents, "name", "name")}
        value={{
          label: selectedStudents[0]?.name,
          value: selectedStudents[0]?.name,
        }}
        handleChange={(result) => {
          if (!result) return;
          const selections = result as DropdownSelectorOption[];

          const names = selections.map((s) => s.value);

          setSelectedStudents(
            sortedStudents.filter((student) => names.includes(student.name))
          );
        }}
        isClearable
        forcePlaceholder
        noOptionsMessage={
          selectedSchool
            ? undefined
            : "Please select a school district to see available students"
        }
      />

      <WorkItemSelector
        workTypes={therapistWorkTypes}
        classId={therapist.classId}
        additionalItems={selectedPartialWorkItems}
        addAdditionalItem={addAdditionalItem}
        removeAdditionalItem={removeAdditionalItem}
        hideBorder
      />

      {showDirectServiceFields && (
        <>
          <KeyboardTimePicker
            label={"Start time"}
            id="time-picker"
            inputVariant="outlined"
            value={dateAndPossiblyTimeWorked}
            autoOk
            onChange={(time) => {
              if (!time) return;
              try {
                setDateAndPossiblyTimeWorked(
                  dateAndPossiblyTimeWorked.set({
                    hour: time.hour,
                    minute: time.minute,
                    second: time.second,
                    millisecond: time.millisecond,
                  })
                );
              } catch {
                return; // disable keyboard input
              }
            }}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
          />

          <Select
            isMulti
            placeholder="Interventions"
            menuPortalTarget={document.body}
            onChange={(interventions) => {
              setSelectedInterventionIds(
                interventions.map(
                  (intervention) =>
                    // For some reason typescript doesn't think this is the type that it actually is.
                    (intervention as unknown as { id: string; value: string })
                      .value
                )
              );
            }}
            options={therapistInterventions?.map((group) => ({
              label: group.title,
              options: group.interventions.map((intervention) => ({
                label: intervention.text,
                value: intervention._id,
              })),
            }))}
            styles={{
              control: (provided) => ({
                ...provided,
                minHeight: "54px",
              }),
              menuPortal: (provided: { [key: string]: unknown }) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
          />

          <TextField
            variant="outlined"
            label="Narrative Note"
            style={{
              width: "100%",
              boxShadow: "none",
              minHeight: "54px",
            }}
            multiline
            value={narrativeNote}
            onChange={(change) => setNarrativeNote(change.target.value)}
          />
        </>
      )}
      <ConfirmDenyButtonAction
        confirmText={"Attestation"}
        denyText={"Cancel"}
        confirmAction={handleSubmission}
        denyAction={() => {
          clearSelected();
          props.setOpen?.(false);
        }}
      />
    </Paper>
  );
}
