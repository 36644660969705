import { useMemo } from "react";

async function load<T>(
  key: string,
  promise: Promise<Response>,
  setState: React.Dispatch<React.SetStateAction<T>>
): Promise<{ ok: boolean }> {
  const res = await promise;
  if (!res.ok) {
    return { ok: false };
  }

  const body = await res.json();

  const data = body.data;
  setState((prev) => ({ ...prev, [key]: data }));
  return { ok: true };
}

export function useLoad() {
  return useMemo(
    () => ({
      load,
    }),
    []
  );
}
