import { Container, Divider } from "@material-ui/core";
import React, { CSSProperties } from "react";
import Drawer from "@material-ui/core/Drawer";
import SectionHeaderText from "./SectionHeaderText";
export interface SideDrawerProps {
  open: boolean;
  onClose: (event: any) => void;
  title?: string;
  altTitleElement?: JSX.Element;
  footer?: JSX.Element;
  style?: {
    title?: CSSProperties;
    drawer?: CSSProperties;
    body?: CSSProperties;
  };
}

export default function SideDrawer(
  props: React.PropsWithChildren<SideDrawerProps>
): JSX.Element {
  const titleElement = React.useMemo(() => {
    if (props.altTitleElement) return props.altTitleElement;
    if (!props.title) return <></>;
    return (
      <SectionHeaderText style={props.style?.title}>
        {props.title}
      </SectionHeaderText>
    );
  }, [props.altTitleElement, props.style?.title, props.title]);

  return (
    <Drawer
      anchor="right"
      elevation={20}
      open={props.open}
      onClose={props.onClose}
    >
      <div
        role="presentation"
        style={Object.assign({}, props.style?.body, {
          width: "500px",
          overflowX: "hidden",
        })}
      >
        <Container disableGutters>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {titleElement}
            <Divider />
            {props.children}
          </div>
        </Container>
      </div>
    </Drawer>
  );
}
