import { Container, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { DropdownSelectorOption, ITimesheetRecord } from "../common/interfaces";
import DropdownSelect from "../components/Input/DropdownSelect";
import WorkItemTable from "../components/WorkItemTable";
import { SchoolContext } from "../context/SchoolContext";
import { WorkItemContext } from "../context/WorkItemContext";
import BasicView from "./BasicView";
import UserTimesheetSubmissionFormModal from "../components/UserTimesheetFormModal";
import { UserActions } from "../components/Input/UserActions";
import { TimesheetContext } from "../context/TimesheetContext";
import { toInterval, unitsToHours } from "../common/Utils";
import * as durationMath from "duration-fns";
import { SideBySide } from "../components/Display/SideBySide";
import { UserContext } from "../context/UserContext";
import TimeSheetTable from "../components/TimeSheetTable";
import LoadingSplash from "../components/Display/LoadingSplash";
import { DateTime, Interval } from "luxon";
import { user_view_loading_missing_therapist } from "../common/Messages";
import useModal from "../hooks/useModal";
import WorkItemForm from "../components/Input/WorkItemForm";
import Column from "../components/Display/Column";
import HeaderWithRightSection from "../components/Display/HeaderWithRightSection";
import { useDateRanges } from "../hooks/useDateRanges";
import { useQuery, useQueryClient } from "react-query";
import { useApi } from "../api/useApi";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "10px",
    width: "100%",
  },
  root: {
    width: "100%",
  },
  flexRow: {
    display: "inline-block",
    // flexDirection: "row",
    // flexGrow: 1
  },
  right: {
    float: "right",
  },
  left: {
    float: "left",
  },
  inlineContainer: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "5px",
  },
  schoolDropdownSelect: {
    width: "50%",
    marginLeft: "auto",
  },
  flexContainer: {
    display: "flex",
  },
  pushRight: {
    marginLeft: "auto",
  },
  schoolSelect: {},
  unitsVsHoursBase: {
    borderRadius: "4px",
    padding: "5px",
  },
  durationMismatch: {
    border: "1px solid red",
  },
  durationMatch: {
    border: "1px solid green",
  },
  sideBySide: {
    padding: "10px",
    marginLeft: "auto",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    [theme.breakpoints.down(750)]: {
      flexDirection: "column",
      overflowY: "scroll",
    },
  },
  table: {
    width: "50%",
    [theme.breakpoints.down(750)]: {
      width: "100%",
    },
  },
}));

export default function UserView(): JSX.Element {
  const classes = useStyles();
  const schoolContext = React.useContext(SchoolContext);
  const { setSchool, setShowInvoiced, school, showInvoiced } =
    React.useContext(WorkItemContext);
  const timesheetContext = React.useContext(TimesheetContext);
  const { therapist } = React.useContext(UserContext);
  const { dateRanges } = useDateRanges();
  const api = useApi();
  const queryClient = useQueryClient();

  const [selectedStartDate, setSelectedStartDate] = React.useState<DateTime>(
    dateRanges.thisWeek.start
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState<DateTime>(
    dateRanges.thisWeek.end
  );

  const serviceRecordQueryParams = {
    dateWorked: {
      $gte: selectedStartDate.toISO(),
      $lte: selectedEndDate.toISO(),
    },
    classId: therapist?.classId,
    therapistUserId: therapist?.userId,
    invoiceId: showInvoiced ? undefined : "null",
  };

  const { data, isLoading: areFilteredWorkItemsLoading } = useQuery({
    queryKey: api.serviceRecord.list.queryKey(serviceRecordQueryParams),
    queryFn: () => api.serviceRecord.list(serviceRecordQueryParams),
    placeholderData: [],
  });
  const filteredWorkItems = data ?? [];
  const schoolFilteredItems = filteredWorkItems.filter((workItem) =>
    school === "All" ? true : workItem.schoolId === school
  );
  const { modal: WorkItemFormModal, toggle: toggleWorkItemFormModal } =
    useModal();

  const [showTimesheetForm, setShowTimesheetForm] =
    React.useState<boolean>(false);

  const options: DropdownSelectorOption[] = schoolContext.schools
    .filter((sch) => therapist?.schoolIds.find((id) => id === sch.id))
    .map((school) => ({
      label: school.name,
      value: school.id,
    }));

  const isWithinCurrentTimeRange = (sheet: ITimesheetRecord) => {
    if (selectedStartDate.valueOf() >= selectedEndDate.valueOf()) return false;
    const currentInterval = toInterval(selectedStartDate, selectedEndDate);
    return (
      currentInterval.contains(DateTime.fromISO(sheet.clockIn)) &&
      currentInterval.contains(DateTime.fromISO(sheet.clockOut))
    );
  };

  const convertSheetToInterval = (sheet: ITimesheetRecord) => {
    return toInterval(
      DateTime.fromISO(sheet.clockIn),
      DateTime.fromISO(sheet.clockOut)
    );
  };

  const applicableTimesheets = timesheetContext.timesheetRecords.filter(
    isWithinCurrentTimeRange
  );

  const totalTimeSheet =
    durationMath.toHours(
      applicableTimesheets
        .map(convertSheetToInterval)
        .map(
          (interval: Interval) =>
            interval.toDuration("milliseconds").toObject().milliseconds ?? 0
        )
        .reduce((prev, curr) => durationMath.sum(prev, curr), {})
    ) -
    applicableTimesheets
      .map((sheet) => parseInt(sheet.break_units))
      .reduce((prev, curr) => prev + curr, 0) *
      0.25;

  const totalUnits = unitsToHours(
    filteredWorkItems
      .filter((item) => !item.invoiceId)
      .reduce((prev, curr) => prev + curr.units, 0)
  );

  const handleSchoolChange = React.useCallback(
    (option: DropdownSelectorOption) => {
      if (option.value) setSchool(option.value);
    },
    [setSchool]
  );

  if (!therapist)
    return (
      <LoadingSplash
        message={"Loading User View"}
        timeoutMessage={user_view_loading_missing_therapist}
      />
    );

  return (
    <BasicView>
      <Paper className={classes.paper}>
        <Column gap="m">
          <Container maxWidth="xl">
            <HeaderWithRightSection
              leftSection={
                <UserActions
                  newTimeSheetAction={() => {
                    setShowTimesheetForm(true);
                  }}
                  newWorkItemAction={() => {
                    toggleWorkItemFormModal();
                  }}
                />
              }
              rightSection={
                <SideBySide
                  totalTimeSheet={totalTimeSheet}
                  totalUnits={totalUnits}
                />
              }
            />
          </Container>

          <Container maxWidth="xl">
            <DropdownSelect
              label={"School"}
              value={{
                label: school,
                value: school,
              }}
              handleChange={(ns) => {
                if (ns) handleSchoolChange(ns as DropdownSelectorOption);
                else setSchool("All");
              }}
              options={options}
              className={classes.schoolSelect}
              includeAll={true}
              isClearable
            />
          </Container>

          <HeaderWithRightSection
            leftSection={
              <Container maxWidth="md">
                <TimeSheetTable
                  timesheetRecords={timesheetContext.timesheetRecords}
                  selectedTherapist={therapist}
                  start={selectedStartDate}
                  end={selectedEndDate}
                  setStartDate={(start: DateTime | null) => {
                    if (start) setSelectedStartDate(start);
                  }}
                  setEndDate={(end: DateTime | null) => {
                    if (end) setSelectedEndDate(end);
                  }}
                  columnsToHide={["therapistId"]}
                  hideMenuButton
                  defaultDensePadding
                  tableContainerProps={{
                    height: "400px",
                    overflowY: "auto",
                  }}
                />
              </Container>
            }
            rightSection={
              <Container maxWidth="md">
                <WorkItemTable
                  rows={schoolFilteredItems}
                  columnsToHide={["therapist", "invoiceId", "dateLogged"]}
                  listStudents
                  start={selectedStartDate}
                  end={selectedEndDate}
                  showInvoicedWorkItems={showInvoiced}
                  setStartDate={(start: DateTime | null) => {
                    if (start) setSelectedStartDate(start);
                  }}
                  setEndDate={(end: DateTime | null) => {
                    if (end) setSelectedEndDate(end);
                  }}
                  setShowInvoicedWorkItems={(show: boolean) =>
                    setShowInvoiced(show)
                  }
                  therapist={therapist}
                  defaultDensePadding
                  tableContainerProps={{
                    height: "400px",
                    overflowY: "auto",
                  }}
                  areRowsLoading={areFilteredWorkItemsLoading}
                  invalidateServiceRecord={() =>
                    queryClient.invalidateQueries(
                      api.serviceRecord.list.queryKey(serviceRecordQueryParams)
                    )
                  }
                />
              </Container>
            }
          />
        </Column>

        {therapist && (
          <WorkItemFormModal>
            <WorkItemForm
              therapist={therapist}
              setOpen={toggleWorkItemFormModal}
            />
          </WorkItemFormModal>
        )}

        <UserTimesheetSubmissionFormModal
          open={showTimesheetForm}
          setOpen={setShowTimesheetForm}
        />
      </Paper>
    </BasicView>
  );
}
