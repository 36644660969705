import React from "react";
import { SimpleActionTable } from "./Display/Table/SimpleActionTable";
import { useInterventions } from "../hooks/useInterventions";
import { OverrideScript } from "../common/Script";
import { InterventionGroup } from "../api/schemas/interventions/groups/schema";
import { Button, Paper } from "@material-ui/core";
import HeaderWithRightSection from "./Display/HeaderWithRightSection";
import { EditIcon, DeleteIcon } from "../icons";

import { useHistory } from "react-router-dom";

interface InterventionsTableProps {
  serviceUuid: string;
}

export default function InterventionsTable({
  serviceUuid,
}: InterventionsTableProps) {
  const { interventionGroups, deleteGroup, addGroup } = useInterventions({
    serviceUuid,
  });

  const history = useHistory();

  const overrideScript: OverrideScript<InterventionGroup> = [
    {
      id: "title",
      text: "Title",
      getField: (group) => group.title,
    },
    {
      id: "interventions",
      text: "Interventions",
      getField: (group) => group.interventionIds.length,
    },
    {
      id: "action",
      text: "Action",
      isAction: true,
      align: "right",
      action: [
        (group) => {
          history.push(`/groups/${group._id}`);
        },
        (group) => deleteGroup(group),
      ],
      actionIcon: [
        <EditIcon key="edit" fontSize="small" />,
        <DeleteIcon key="delete" fontSize="small" />,
      ],
    },
  ];

  return (
    <Paper style={{ width: "100%" }}>
      <SimpleActionTable
        population={interventionGroups}
        title={"Intervention Groups"}
        overrideScript={overrideScript}
        tableContainerProps={{
          height: "400px",
          overflowY: "auto",
        }}
      />
      <HeaderWithRightSection
        leftSection={<></>}
        rightSection={
          <Button
            style={{ margin: "5px" }}
            color="primary"
            variant="outlined"
            onClick={async () => {
              const group = await addGroup({
                title: "New barrier",
                classUuid: serviceUuid,
              });

              if (group) {
                history.push(`/groups/${group._id}`);
              }
            }}
          >
            New barrier
          </Button>
        }
      />
    </Paper>
  );
}
