import React, { useContext, useState } from "react";
import { IStudent } from "../../common/interfaces";
import { ArrowDownwardIcon, DeleteIcon } from "../../icons";
import SortableTable from "../Display/Table/SortableTable";
import { OverrideScript } from "../../common/Script";
import Row from "../Display/Row";
import { TextField, Button, Paper } from "@material-ui/core";
import Column from "../Display/Column";
import useForm from "../../hooks/useForm";
import useModal from "../../hooks/useModal";
import SectionHeaderText from "../Display/SectionHeaderText";
import { DateTime } from "luxon";
import { RangeDatePicker } from "../RangeDatePicker/RangeDatePicker";
import { WorkItemContext } from "../../context/WorkItemContext";
import { useStudentServiceDetails } from "../../hooks/useStudentServiceDetails";

interface NewStudentInputProps {
  students: IStudent[];
  addStudent: (student: IStudent) => void;
  removeStudent: (student: IStudent) => void;
}

export function NewStudentInput(props: NewStudentInputProps): JSX.Element {
  const { students, addStudent, removeStudent } = props;
  const [selectedStudent, setSelectedStudent] = useState<IStudent | undefined>(
    undefined
  );
  const { thisWeek, timeRanges } = useContext(WorkItemContext);
  const [start, setStart] = useState<DateTime>(thisWeek.start);
  const [end, setEnd] = useState<DateTime>(thisWeek.end);
  const { downloadCSV } = useStudentServiceDetails();

  const newStudentForm = useForm({
    initialValues: { name: "" },
  });

  const { modal: StudentDetailsModal, toggle } = useModal();

  const script: OverrideScript<IStudent> = [
    {
      id: "name",
      text: "Name",
      getField: (student: IStudent) => student.name,
    },
    {
      id: "actions",
      text: "Actions",
      action: [
        (student) => {
          setSelectedStudent(student);
          toggle();
        },
        removeStudent,
      ],
      actionIcon: [
        <ArrowDownwardIcon key="delete" fontSize="small" />,
        <DeleteIcon key="delete" fontSize="small" />,
      ],
      isAction: true,
      align: "right",
    },
  ];

  return (
    <Paper>
      <SortableTable
        label="Students"
        script={script}
        rows={students}
        hideDensePaddingSwitch
        hidePagination
        defaultToDensePadding
        altFooter={
          <form
            onSubmit={newStudentForm.injectValues((values) => {
              addStudent({ name: values.name, sasid: "000" });
              newStudentForm.reset();
            })}
            style={{ width: "100%" }}
          >
            <Row gap="l" style={{ padding: "10px" }}>
              <TextField
                label="Name"
                variant="outlined"
                style={{ width: "100%" }}
                {...newStudentForm.register("name")}
              />
              <Button color="primary" variant="outlined" type="submit">
                Add
              </Button>
            </Row>
          </form>
        }
      />
      <StudentDetailsModal onClose={() => setSelectedStudent(undefined)}>
        <Column style={{ padding: "10px" }}>
          <SectionHeaderText>
            Download Student Service Details
          </SectionHeaderText>
          {selectedStudent && (
            <SectionHeaderText variant="subtitle1">
              Student: {selectedStudent.name}
            </SectionHeaderText>
          )}
          <Row gap="m">
            <RangeDatePicker
              style={{ width: "100%" }}
              start={start}
              end={end}
              setStart={(start: DateTime | null) => {
                if (start) {
                  setStart(start);
                }
              }}
              setEnd={(end: DateTime | null) => {
                if (end) {
                  setEnd(end);
                }
              }}
              predefinedOptions={Array.from(timeRanges.values())}
            />
            <Button
              color="primary"
              variant="outlined"
              onClick={async () => {
                if (!selectedStudent) return;
                await downloadCSV({ name: selectedStudent.name, start, end });
                toggle();
                setSelectedStudent(undefined);
              }}
            >
              Download
            </Button>
          </Row>
        </Column>
      </StudentDetailsModal>
    </Paper>
  );
}
