import React, { CSSProperties } from "react";
import { clsx } from "../../clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Row from "./Row";

interface HeaderWithRightSectionProps {
  leftSection: React.ReactNode;
  rightSection?: React.ReactNode;
  style?: CSSProperties;
}

const useStyles = makeStyles({
  rightSection: {
    marginLeft: "auto",
  },
  mainSection: {
    flexGrow: 1,
  },
});

export default function HeaderWithRightSection(
  props: HeaderWithRightSectionProps
): JSX.Element {
  const { leftSection, rightSection } = props;
  const styles = useStyles();
  return (
    <Row style={{ flexGrow: 1, ...props.style }}>
      <div className={styles.mainSection}>{leftSection}</div>
      <div className={clsx(styles.rightSection)}>{rightSection}</div>
    </Row>
  );
}
