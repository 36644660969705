import { useContext, useMemo } from "react";
import { ServicesContext } from "../context/ServicesContext";
import {
  IClassWithoutWorkTypesOrGrades,
  IGrade,
  IWorkType,
  UuidResponse,
} from "../common/interfaces";
import { unwrapResponse, useApi } from "../api/useApi";
import Toaster, { ToastType } from "../common/Toaster";

interface UseServiceProps {
  uuid?: string;
}

export default function useService({ uuid }: UseServiceProps) {
  const { classes, triggerRefresh } = useContext(ServicesContext);
  const api = useApi();
  return useMemo(
    () => ({
      service: uuid ? classes[uuid] : undefined,
      updateClass: async (
        updates: IClassWithoutWorkTypesOrGrades,
        classId: string
      ) => {
        try {
          await unwrapResponse<UuidResponse>(
            api.configuration.classes.update(updates, classId)
          );

          Toaster(`Successfully updated service.`, ToastType.good);
          triggerRefresh();
          return true;
        } catch (e) {
          Toaster((e as Error).message, ToastType.error);
          return false;
        }
      },
      addGrade: async (grade: IGrade, classId: string) => {
        try {
          await unwrapResponse<UuidResponse>(
            api.configuration.classes.grades.add(grade, classId)
          );

          Toaster(`Successfully added new grade to service.`, ToastType.good);
          triggerRefresh();
          return true;
        } catch (e) {
          Toaster((e as Error).message, ToastType.error);
          return false;
        }
      },
      updateGrade: async (
        updates: IGrade,
        classId: string,
        gradeId: string
      ) => {
        try {
          await unwrapResponse<UuidResponse>(
            api.configuration.classes.grades.update(updates, classId, gradeId)
          );

          Toaster(`Successfully updated grade.`, ToastType.good);
          triggerRefresh();
          return true;
        } catch (e) {
          Toaster((e as Error).message, ToastType.error);
          return false;
        }
      },
      removeGrade: async (classId: string, gradeId: string) => {
        try {
          await unwrapResponse(
            api.configuration.classes.grades.delete(classId, gradeId)
          );

          Toaster(`Successfully removed grade from service.`, ToastType.good);
          triggerRefresh();
          return true;
        } catch (e) {
          Toaster((e as Error).message, ToastType.error);
          return false;
        }
      },
      addType: async (type: IWorkType, classId: string) => {
        try {
          await unwrapResponse<UuidResponse>(
            api.configuration.classes.workTypes.add(type, classId)
          );

          Toaster(
            `Successfully added new work type to service.`,
            ToastType.good
          );
          triggerRefresh();
          return true;
        } catch (e) {
          Toaster((e as Error).message, ToastType.error);
          return false;
        }
      },
      updateType: async (
        workTypeId: string,
        updates: IWorkType,
        classId: string
      ) => {
        try {
          await unwrapResponse<UuidResponse>(
            api.configuration.classes.workTypes.update(
              updates,
              classId,
              workTypeId
            )
          );

          Toaster(`Successfully updated work type.`, ToastType.good);
          triggerRefresh();
          return true;
        } catch (e) {
          Toaster((e as Error).message, ToastType.error);
          return false;
        }
      },
      removeType: async (workTypeId: string, classId: string) => {
        try {
          await unwrapResponse<UuidResponse>(
            api.configuration.classes.workTypes.delete(classId, workTypeId)
          );

          Toaster(
            `Successfully removed worktype from service.`,
            ToastType.good
          );
          triggerRefresh();
          return true;
        } catch (e) {
          Toaster((e as Error).message, ToastType.error);
          return false;
        }
      },
    }),
    [api.configuration.classes, classes, triggerRefresh, uuid]
  );
}
