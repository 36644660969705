import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { CSSProperties } from "react";
import { clsx } from "../../clsx";
import Row from "../Display/Row";

export interface ConfirmDenyButtonActionProps {
  confirmText?: string;
  denyText?: string;
  confirmAction?: () => void;
  denyAction: () => void;
  style?: CSSProperties;
  confirmActionIsSubmit?: boolean;
}

const useStyles = makeStyles({
  buttonContainer: {
    paddingTop: "10px",
  },
  confirmColumn: {
    width: "40%",
    margin: "auto 0",
  },
  denyColumn: {
    width: "40%",
    margin: "auto 0",
  },
  confirmButton: {},
  denyButton: {},
});

export function ConfirmDenyButtonAction(
  props: ConfirmDenyButtonActionProps
): JSX.Element {
  const { confirmText, denyText, confirmAction, denyAction } = props;
  const styles = useStyles();
  return (
    <Row style={{ padding: "10px" }} gap="l">
      <Button
        className={clsx(styles.denyButton)}
        variant="outlined"
        color="secondary"
        onClick={denyAction}
        fullWidth
      >
        {denyText ?? "Deny"}
      </Button>
      <Button
        className={clsx(styles.confirmButton)}
        variant="contained"
        color="primary"
        onClick={confirmAction ? confirmAction : () => undefined}
        fullWidth
        type={props.confirmActionIsSubmit ? "submit" : "button"}
      >
        {confirmText ?? "Confirm"}
      </Button>
    </Row>
  );
}
