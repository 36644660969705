import { KeyboardDatePicker } from "@material-ui/pickers";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { DateTime } from "luxon";
import React from "react";

export interface SimpleDatePickerProps {
  label: string;
  value: ParsableDate;
  onChange: (date: DateTime | null, value?: string | null | undefined) => void;
  className?: string;
  style?: { [key: string]: string };
}

export function SimpleDatePicker(props: SimpleDatePickerProps): JSX.Element {
  const { label, value, onChange, className, style } = props;
  return (
    <KeyboardDatePicker
      disableToolbar
      variant="inline"
      format="MM/dd/yyyy"
      id={`id-${label}`}
      label={label}
      value={value}
      onChange={onChange}
      KeyboardButtonProps={{
        "aria-label": "change DateTime",
      }}
      inputVariant="outlined"
      className={className}
      autoOk
      style={style}
    />
  );
}
