import React from "react";
import Paper from "@material-ui/core/Paper";
import { ISchool } from "../common/interfaces";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { SchoolContext } from "../context/SchoolContext";
import SortableTable from "./Display/Table/SortableTable";
import { Action, OverrideScript, WithIndex } from "../common/Script";
import { AddIcon } from "../icons";
import SectionHeaderText from "./Display/SectionHeaderText";
import HeaderWithRightSection from "./Display/HeaderWithRightSection";

interface ISchoolTableProps {
  onAdd: () => void;
  onEdit: (school: ISchool) => void;
  onDelete: (school: ISchool) => void;
}

export default function SchoolTable(props: ISchoolTableProps): JSX.Element {
  const { schools } = React.useContext(SchoolContext);

  const startEdit = React.useCallback<Action<ISchool>>(
    (type: WithIndex<ISchool>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { index, ...rest } = type;
      props.onEdit(rest as ISchool);
    },
    [props]
  );

  const startDelete = React.useCallback<Action<ISchool>>(
    (type: WithIndex<ISchool>) => {
      props.onDelete(type);
    },
    [props]
  );

  const script: OverrideScript<ISchool> = React.useMemo(
    () => [
      {
        id: "name",
        text: "Name",
        getField: (s: ISchool) => s.name,
      },
      {
        id: "address",
        text: "Address",
        getField: (s: ISchool) => s.address,
      },
      {
        id: "prefix",
        text: "Invoice Prefix",
        getField: (s: ISchool) => s.invoicePrefix,
      },
      {
        id: "students",
        text: "Students",
        getField: (s: ISchool) => s.students.length,
      },
      {
        isAction: true,
        id: "action",
        text: "Action",
        align: "right",
        action: [startEdit, startDelete],
        actionIcon: [
          <EditIcon key="edit" fontSize="small" />,
          <DeleteIcon key="delete" fontSize="small" />,
        ],
      },
    ],
    [startDelete, startEdit]
  );

  return (
    <Paper>
      <SortableTable
        label={"Districts"}
        script={script}
        rows={schools}
        defaultSortKey="name"
        defaultToDensePadding
        defaultRowsPerPage={10}
        altToolbar={
          <HeaderWithRightSection
            leftSection={<SectionHeaderText>Districts</SectionHeaderText>}
            rightSection={
              <IconButton color="primary" onClick={() => props.onAdd()}>
                <AddIcon />
              </IconButton>
            }
          />
        }
      />
    </Paper>
  );
}
